import React from "react";
import { useAppSelector } from "../../app/store";
import { RenderImage } from "./RenderImage";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

type Props = {};

const ImageModal = (props: Props) => {
  const data = useAppSelector((state) => state.imageLink.imageLink);

  return (
    <div>
      
      <div className="modal modal-bottom sm:modal-middle" id="image-modal">
        <div className="modal-box">
          {data ? (
            <RenderImage
              imageLocation={data}
              imageAlt={"Failed to Load Image"}
            />
          ) : null}
          <div className="modal-action">
            <a href="#" className="btn">
              Close
            </a>
          </div>
        </div>
      </div>
      
      <div className="modal modal-bottom sm:modal-middle" id="image-modal1">
        <div className="modal-box">
          <Carousel>
            {data && data.length && data.map((image: any, key: any) => (
              <RenderImage
                imageLocation={image}
                imageAlt={"Failed to Load Image"}
              />
            ))}
          </Carousel>
          <div className="modal-action">
            <a href="#" className="btn">
              Close
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
