import axios from "axios";
import { GoVerified } from "react-icons/go";
import { MdLocationOn } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetAllNativesQuery } from "../../app/services/natives";
import { Gender } from "../../models/profiles";
import { nativeCount } from "../../utils/constants";
import { RenderProfileImage } from "./RenderImage";

type ProfileCardProps = {
  data: {
    id: string;
    profilePhoto: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    nativeVillageId: string;
    city: string;
    verified: boolean;
    isDeceased: boolean;
    dob: any;
    homeOwnerId: string;
  };
};

const ProfileCard = ({ data }: ProfileCardProps) => {
  const natives = useGetAllNativesQuery({ take: nativeCount, skip: 0 });
  const gender = data.gender;

  function dateDiffInYears(a: any, b: any) {
    return Math.floor(b.getFullYear() - a.getFullYear());
  }

  const a = new Date(data.dob),
    b = new Date(),
    age = dateDiffInYears(a, b);

  const params = useParams();

  const removeMember = (memberId: string) => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");
    axios
      .put(
        `${baseUrl}user/${params.id}/remove-homeMember/${memberId}`,
        {},
        {
          headers: {
            authorization: token ? token : "",
          },
        }
      )
      .then((res) => {
        toast.success("User Removed Successfully");
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <div className="card overflow-clip bg-base-100 shadow-xl m-3">
      <figure className="p-2 w-32 m-auto">
        <RenderProfileImage
          imageLocation={data.profilePhoto}
          firstName={data.firstName}
          lastName={data.lastName}
        />
      </figure>
      {!data.homeOwnerId && (
        <span className="absolute text-white font-bold self-end bg-orange-600 px-2">
          owner
        </span>
      )}
      <div className="card-body p-3">
        <h2 className="flex items-center">
          {data.isDeceased ? "(late)" : null} {data.firstName} {data.lastName}
          &nbsp;
          {data.verified && (
            <GoVerified size={15} className="rounded-full text-blue-600" />
          )}{" "}
        </h2>

        {/* <p className='flex items-center'>  {gender === 'MALE' && <FaMale />} {gender === 'FEMALE' && <FaFemale />} {gender === 'OTHERS' && <FaTransgenderAlt />} {gender}</p> */}
        {/* {data.nativeVillageId ? <p className='flex items-center'><MdLocationOn /> {getNativeName(data.nativeVillageId, natives?.data)}</p> : null} */}
        {/* <p className='flex items-center'>  {gender === 'MALE' && <FaMale />} {gender === 'FEMALE' && <FaFemale />} {gender === 'OTHERS' && <FaTransgenderAlt />} {gender}</p> */}
        {data.city ? (
          <p className="flex items-center">
            <MdLocationOn />{" "}
            {/* {getNativeName(data.nativeVillageId, natives?.data)} */}
            {data.city}
          </p>
        ) : null}

        {data.dob && age ? (
          <p className="flex items-center">
            {"Age: "}
            {age} Years
          </p>
        ) : (
          "No Date of birth"
        )}

        <div className="card-actions justify-between items-center">
          <Link
            to={`/user/${data.id}`}
            className="btn btn-xs btn-outline btn-accent m-auto"
          >
            View Profile
          </Link>
        </div>
        <div className="card-actions justify-between items-center">
          <button
            onClick={() => removeMember(data.id)}
            className="btn btn-sm btn-error m-auto mt-1"
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
