import React from "react";

// import "./Pagination.css";

type Props = {
  total: number;
  usersPerPage: number;
  setCurrentPage: any;
  currentPage: number;
};

const Pagination = (props: Props) => {
  let pages = [];

  for (let i = 1; i <= Math.ceil(props.total / props.usersPerPage); i++) {
    pages.push(i);
  }

  return (
    <div style={{
      // border: "2px solid red",
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginTop: "30px",
    }}>
      {pages.map((page, index) => {
        return (
          <button
            key={index}
            onClick={() => props.setCurrentPage(page)}
            // className={page == props.currentPage ? "active" : ""}
            className={`btn border-primary border-2 m-1 text-base ${
              page == props.currentPage ? "bg-primary text-white" : "bg-white text-primary"
            }`}
          >
            {page}
          </button>
        );
      })}
    </div>
  );
};

export default Pagination;
