import { ErrorMessage } from "@hookform/error-message";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { GoVerified } from "react-icons/go";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useGetAllNativesQuery } from "../../app/services/natives";
import { useDirectoryQuery } from "../../app/services/userData";
import { nativeCount } from "../../utils/constants";
import { getNativeName } from "../../utils/utils";
import Loading from "../utils/Loading";
import { RenderProfileImage } from "../utils/RenderImage";
import { MdLocationOn } from "react-icons/md";
import { Gender } from "../../models/profiles";

import { ZonesData } from "../../DropData";
import axios from "axios";

import Selector from "../utils/DropDownSearch";
import Pagination from "../utils/Pagination";
import { toast } from "react-toastify";

const SearchBar = () => {
  const { data } = useGetAllNativesQuery({ take: nativeCount, skip: 0 });

  const { search } = useLocation();
  const url = new URLSearchParams(search);

  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    city: "",
    area: "",
  });

  const [data1, setData1] = useState([]);

  const [cities, setCities] = useState<any | []>([]);
  const [areas, setAreas] = useState<any | []>([]);
  const [zones, setZones] = useState<any | {}>(ZonesData);

  const getData = () => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");

    if ((filters.city &&
      filters.city.length &&
      filters.area &&
      filters.area.length) ||
    (url.get("zone") && url.get("area"))) {
      setLoading(true);

      const city = filters.city.toLowerCase();
      const area = filters.area.toLowerCase();

      const p_city: any = url.get("zone")?.toString();
      const p_area: any = url.get("area")?.toString();

      axios
        .post(
          `${baseUrl}search/allDirectory`,
          {
            city: p_city
              ? p_city.charAt(0).toUpperCase() +
                p_city.slice(1, p_city.length).toLowerCase()
              : city.charAt(0).toUpperCase() + city.slice(1),
            area: p_area
              ? p_area.charAt(0).toUpperCase() +
                p_area.slice(1, p_area.length).toLowerCase()
              : area.charAt(0).toUpperCase() + area.slice(1),
          },
          {
            headers: {
              authorization: token ? token : "",
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setData1(res.data.data);
          console.log(res.data.data);
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
        });
    }
  };

  const getCities = () => {
    setCities(Object.keys(ZonesData));
  };

  const getAreas = () => {
    setAreas(zones[filters.city]);
  };

  const checkParams = () => {
    if (filters.city || filters.area) {
      const params = new URLSearchParams(window.location.search);
      if (filters.city) {
        params.set("zone", filters.city);
      }
      if (filters.area) {
        params.set("area", filters.area);
      }
      if (filters.city && filters.area) {
        window.history.replaceState(
          {},
          "",
          `${window.location.pathname}?${params}`
        );
      }
    }
  };

  useEffect(() => {
    getCities();
    return () => {};
  }, []);

  useEffect(() => {
    checkParams();
    getAreas();
    getData();
    return () => {};
  }, [filters]);

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const lastUserIndex = currentPage * usersPerPage;
  const firstUserIndex = lastUserIndex - usersPerPage;
  const data2 = data1 ? data1.slice(firstUserIndex, lastUserIndex) : [];

  return (
    <>
      <div
        className="font-Poppins flex items-center justify-center flex-wrap"
        style={{
          zIndex: 100,
        }}
      >
        <Selector
          setFilters={setFilters}
          filters={filters}
          name="Zone"
          data={cities}
          url={url}
        />
        <Selector
          setFilters={setFilters}
          filters={filters}
          name="Area"
          data={areas && areas.length ? areas : []}
          url={url}
        />
      </div>

      {loading ? <Loading /> : null}
      <div className="flex flex-wrap justify-center items-center">
        {data2 && data2.length !== 0 ? (
          data2.map((user: any) => <ProfileCard key={user.id} data={user} />)
        ) : (
          <p className="text-md italic font-bold">--- no user found---</p>
        )}
      </div>

      <Pagination
        total={data1 ? data1.length : 0}
        usersPerPage={usersPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
};

type ProfileCardProps = {
  data: {
    id: string;
    profilePhoto: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    nativeVillageId: string;
    verified: boolean;
    isDeceased: boolean;
    state: string;
    city: string;
    isHeadOfHousehold: boolean;
    dob: any;
    area: string;
  };
};

const ProfileCard = ({ data }: ProfileCardProps) => {
  const natives = useGetAllNativesQuery({ take: nativeCount, skip: 0 });
  const gender = data.gender;

  function dateDiffInYears(a: any, b: any) {
    return Math.floor(b.getFullYear() - a.getFullYear());
  }

  const a = new Date(data.dob),
    b = new Date(),
    age = dateDiffInYears(a, b);

  const params = useParams();

  const addMember = (memberId: string) => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");
    axios
      .put(
        `${baseUrl}user/${params.id}/add-homeMember/${memberId}`,
        {},
        {
          headers: {
            authorization: token ? token : "",
          },
        }
      )
      .then((res) => {
        toast.success("User Created Successfully");
        // window.location.reload();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <div className="card overflow-clip bg-base-100 shadow-xl m-3">
      <figure className="p-2 w-32 m-auto">
        <RenderProfileImage
          imageLocation={data.profilePhoto}
          firstName={data.firstName}
          lastName={data.lastName}
        />
      </figure>
      <div className="card-body p-3">
        <h2 className="flex items-center">
          {data.isDeceased ? "(late)" : null} {data.firstName} {data.lastName}
          &nbsp;
          {data.verified && (
            <GoVerified size={15} className="rounded-full text-blue-600" />
          )}{" "}
        </h2>

        {/* <p className='flex items-center'>  {gender === 'MALE' && <FaMale />} {gender === 'FEMALE' && <FaFemale />} {gender === 'OTHERS' && <FaTransgenderAlt />} {gender}</p> */}
        {data.area ? (
          <p className="flex items-center">
            <MdLocationOn />{" "}
            {/* {getNativeName(data.nativeVillageId, natives?.data)} */}
            {data.area}
          </p>
        ) : null}

        {data.dob && age ? (
          <p className="flex items-center">
            {"Age: "}
            {age} Years
          </p>
        ) : (
          "No Date of birth"
        )}

        <div className="card-actions justify-between items-center">
          <div
            className="btn btn-xs btn-outline btn-accent m-auto"
            onClick={() => {
              addMember(data.id);
            }}
          >
            Add to home
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
