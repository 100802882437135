import { ErrorMessage } from "@hookform/error-message";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { GoVerified } from "react-icons/go";
import { Link, useSearchParams } from "react-router-dom";
import { useGetAllNativesQuery } from "../../app/services/natives";
import { useSearchUsersQuery } from "../../app/services/userData";
import { nativeCount } from "../../utils/constants";
import { getNativeName } from "../../utils/utils";
import Loading from "../utils/Loading";
import { RenderProfileImage } from "../utils/RenderImage";
import { MdLocationOn } from "react-icons/md";
import { Gender } from "../../models/profiles";

import InfiniteScroll from "react-infinite-scroll-component";
import Pagination from "../utils/Pagination";

type Props = {};
type Inputs = {
  searchString: string;
};
const SearchBar = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams({});
  // const [searchString, setSearchString] = useState('');

  let { data, error, isFetching, isLoading } = useSearchUsersQuery(
    searchParams.get("search") ?? skipToken
  );

  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    resetField,
    trigger,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (searchData) => {
    let newUser = searchData.searchString;
    if (!newUser) return;

    setSearchParams({ search: searchData.searchString });
    // setState([])
    // call()
  };

  // const [state, setState] = useState([])
  // const [hasMore, setHasMore] = useState(true)

  // const fetchMoreData = () => {

  //     if (state.length == data.data.length) {
  //         setHasMore(false);
  //         return;
  //     }

  //     setTimeout(() => {
  //         setState(data.data.slice(0, (state.length + 25) > data.data.length ? data.data.length : state.length + 25))
  //     }, 500);
  // };

  // const call = () => {
  //     setState(data.data.slice(0, 25))
  // }

  useEffect(() => {
    if (searchParams.get("search")) {
      setValue("searchString", searchParams.get("search") ?? "");
      setFocus("searchString");
    }
  }, []);

  // useEffect(() => {

  //     if (data) {
  //         // call()
  //     }

  // }, [data])

  // console.log(data);

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const lastUserIndex = currentPage * usersPerPage;
  const firstUserIndex = lastUserIndex - usersPerPage;
  const data1 = data ? data.data.slice(firstUserIndex, lastUserIndex) : [];

  return (
    <>
      <form
        className="form-control my-3"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="input-group relative flex justify-center items-center ">
          <input
            type="text"
            placeholder="Search…"
            className="input w-3/4 input-bordered"
            {...register("searchString", {
              required: true,
              minLength: {
                value: 3,
                message: "Search string must be at least 3 characters long",
              },
            })}
          ></input>
          <button
            type="reset"
            className="btn absolute right-20 lg:right-60 btn-xs lg:btn-sm btn-circle btn-outline"
            onClick={() => {
              resetField("searchString");
              setSearchParams({});
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <button className="btn btn-primary" type="submit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </button>
        </div>
        <ErrorMessage
          errors={errors}
          name="searchString"
          render={({ message }) => (
            <p className="text-red-500 text-center font-semibold">{message}</p>
          )}
        />
      </form>

      <div className="divider font-semibold">
        SEARCH RESULTS for
        <span className="font-bold italic">"{searchParams.get("search")}"</span>
      </div>

      {error ? (
        <div className="text-red-400 text-xl text-center font-bold">
          Error Fetching Data
        </div>
      ) : null}

      {isFetching ? <Loading /> : null}
      <div className="flex flex-wrap justify-center items-center">
        {data1 && data1.length !== 0 ? (
          data1.map((user: any) => <ProfileCard key={user.id} data={user} />)
        ) : (
          <p className="text-md italic font-bold">--- no user found---</p>
        )}
      </div>

      {/* <Pagination
        total={1000}
        usersPerPage={usersPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      /> */}

      <Pagination
        total={data ? data.data.length : 0}
        usersPerPage={usersPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />

      {/* <InfiniteScroll
                style={{
                    width: '100%'
                }}
                dataLength={state.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={state.length ? <Loading /> : ""}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
            >
                <div className='flex flex-wrap justify-center items-center'>
                    {state && state.length !== 0 ? state.map((user: any) => (
                        <ProfileCard key={user.id} data={user} />
                    )) : <p className='text-md italic font-bold'>--- no user found---</p>}
                </div>
            </InfiniteScroll> */}
    </>
  );
};

type ProfileCardProps = {
  data: {
    id: string;
    profilePhoto: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    nativeVillageId: string;
    city: string;
    verified: boolean;
    isDeceased: boolean;
    dob: any;
    area: string;
  };
};

const ProfileCard = ({ data }: ProfileCardProps) => {
  const natives = useGetAllNativesQuery({ take: nativeCount, skip: 0 });
  const gender = data.gender;

  function dateDiffInYears(a: any, b: any) {
    return Math.floor(b.getFullYear() - a.getFullYear());
  }

  const a = new Date(data.dob),
    b = new Date(),
    age = dateDiffInYears(a, b);

  return (
    <div className="card overflow-clip bg-base-100 shadow-xl m-3">
      <figure className="p-2 w-32 m-auto">
        <RenderProfileImage
          imageLocation={data.profilePhoto}
          firstName={data.firstName}
          lastName={data.lastName}
        />
      </figure>
      <div className="card-body p-3">
        <h2 className="flex items-center">
          {data.isDeceased ? "(late)" : null} {data.firstName} {data.lastName}
          &nbsp;
          {data.verified && (
            <GoVerified size={15} className="rounded-full text-blue-600" />
          )}{" "}
        </h2>

        {/* <p className='flex items-center'>  {gender === 'MALE' && <FaMale />} {gender === 'FEMALE' && <FaFemale />} {gender === 'OTHERS' && <FaTransgenderAlt />} {gender}</p> */}
        {/* {data.nativeVillageId ? <p className='flex items-center'><MdLocationOn /> {getNativeName(data.nativeVillageId, natives?.data)}</p> : null} */}
        {/* <p className='flex items-center'>  {gender === 'MALE' && <FaMale />} {gender === 'FEMALE' && <FaFemale />} {gender === 'OTHERS' && <FaTransgenderAlt />} {gender}</p> */}
        {data.area ? (
          <div>
            <p className="flex items-center">
              <MdLocationOn />{" "}
              {getNativeName(data.nativeVillageId, natives?.data)}
              {/* {data.area} */}
            </p>
            <p className="flex items-center">({data.area})</p>
          </div>
        ) : null}

        {data.dob && age ? (
          <p className="flex items-center">
            {"Age: "}
            {age} Years
          </p>
        ) : (
          "No Date of birth"
        )}

        <div className="card-actions justify-between items-center">
          <Link
            to={`/user/${data.id}`}
            className="btn btn-xs btn-outline btn-accent m-auto"
          >
            View Profile
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
