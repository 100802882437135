import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type Sticker = {
  id: string;
  firstName: string;
  lastName: string;
  mobile: string;
  address: string;
  nativeVillage: string;
  userId: string;
  photo: string;
};

function Stickers() {
  const params = useParams();

  const [data, setData] = useState<Sticker>();

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");
    axios
      .post(
        `${baseUrl}stickers/${params.id}`,
        {},
        {
          headers: {
            authorization: token ? token : "",
          },
        }
      )
      .then((res: any) => {
        // console.log(res.data.data)
        setData(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
      });

    return () => {};
  }, []);

  return (
    <div>
      {data && data.id ? (
        <div className="flex mx-auto my-10 items-center justify-between bg-white rounded-lg md:max-w-xl cursor-pointer">
          <div className="flex flex-col justify-between p-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
              {data.firstName} {data.lastName}
            </h5>
            <p className="flex justify-between mb-3 mr-10 font-normal text-gray-700 ">
              <span>{data.mobile}</span>
              <span>({data.nativeVillage})</span>
            </p>
            <p className="mb-3 font-normal text-gray-700">
              {data.address}.
            </p>
          </div>
          <img
            className="object-cover w-full rounded-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-lg"
            src={
              data.photo
                ? data.photo
                : `https://avatars.dicebear.com/api/initials/${
                    data.firstName + " " + data.lastName
                  }.png`
            }
            alt=""
          />
        </div>
      ) : (
        <h1 className="text-center m-10">No Sticker found.</h1>
      )}
    </div>
  );
}

export default Stickers;
