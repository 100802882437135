import React, { useState } from "react";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Form,
  Popup,
  ColumnFixing,
  Editing,
  Pager,
  Paging,
  Scrolling,
  Summary,
  TotalItem,
  RemoteOperations,
  Sorting,
  Toolbar,
  Item as ToolBarItem,
} from "devextreme-react/data-grid";
import { Item, SimpleItem } from "devextreme-react/form";
import { toast } from "react-toastify";
import {
  createEvents,
  getAllEvents,
  updateEvents,
  uploadImage,
} from "../../services/events/events.api";
import { Events } from "../../models/events";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import FileUploader from "devextreme-react/file-uploader";
import Button from "devextreme-react/button";
import { useAppDispatch } from "../../app/store";
import { setImageLink } from "../../features/imageModalSlice";
import { AiOutlinePlusSquare } from "react-icons/ai";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

type Props = {};

const EventsTable = (props: Props) => {
  let fileUploaderRef = React.useRef(null);
  let datagridRef = React.useRef(null);
  const dispatch = useAppDispatch();

  function editCellRender(cellInfo: { data: Events; value: string }) {
    let files: any | undefined = [];

    return (
      <>
        {/* <form encType="multipart/form-data"> */}
        <FileUploader
          // ref={fileUploaderRef}
          // multiple={true}
          selectButtonText="Select photo(s)"
          labelText=""
          accept="image/*"
          uploadMode="useButtons"
          uploadFile={async (e) => {
            // for (const file of files) {
            //   await uploadImage(cellInfo.data.id, file);
            //   // console.log(file);
            // }
            // for (let index = 0; index < files.length; index++) {
            //   const e = files[index];
            //   await uploadImage(cellInfo.data.id, e);
            // }
            await uploadImage(cellInfo.data.id, e);
          }}
          onValueChanged={async (e) => {
            files = e.value;
            // console.log("files", files);
            // await uploadImage(cellInfo.data.id, files);
          }}
          onUploadError={(e) => {
            toast.error(e.message);
          }}
          onUploaded={(e) => {
            toast.success("Image(s) uploaded successfully");
          }}
          invalidFileExtensionMessage="Only images are allowed"
        />
        {/* <Button onClick={() => {
                        // @ts-ignore
                        console.log(fileUploaderRef.current._instance._uploadAction());
                    }} >
                        Upload
                    </Button> */}
        {/* </form> */}

        {/* <input
          type={"file"}
          multiple
          onChange={(e) => {            
            files = e.target.files
          }}
        />
        <button
          onClick={async () => {
            await uploadImage(cellInfo.data.id, files);
            console.log(files);
          }}
        >click</button> */}
      </>
    );
  }

  const store = new CustomStore({
    key: "id",
    errorHandler: (error: any) => {
      toast.error(error.response.data.msg);
    },

    load: async function (loadOptions) {
      const res = await getAllEvents(loadOptions.skip, loadOptions.take);
      return {
        data: res.data.data,
        totalCount: res.data.count,
        summary: [res.data.count],
      };
    },

    insert: async function (event: Events) {
      const res = await createEvents(event);

      return res.data;
    },

    update: async function (id: string, event: any) {
      // console.log(event);

      // console.log(fileUploaderRef.current?.dispatchEvent(new Event("submit")));

      const res = await updateEvents(id, event);
      if (fileUploaderRef.current) {
        // fileUploaderRef.current.submit();
      }
      return res.data;
    },

    onInserted: function (e) {
      toast.success("Event Created Successfully");
    },

    onUpdated: function (e) {
      dataSource.reload();
      toast.success("Event Updated Successfully");
    },
  });

  // ===== or inside the DataSource =====
  const dataSource = new DataSource({
    store: store,
  });

  const allowedPageSizes = [5, 10, 15, 20];

  const categoryEditorOptions = {
    items: [
      "Guru Bhagwant",
      "Greetings",
      "Social",
      "Openings",
      "Sports",
      "Shok Sandesh",
      "Custom",
    ],
    searchEnabled: true,
    value: "",
  };

  const [images, setImages] = useState([]);

  let x: any = [];

  return (
    <>
      <DataGrid
        dataSource={dataSource}
        columnAutoWidth={true}
        showBorders={true}
        ref={datagridRef}
        rowAlternationEnabled={true}
        columnHidingEnabled={true}
        wordWrapEnabled={true}
      >
        <Sorting mode="none" />
        <RemoteOperations
          // filtering={true}
          paging={true}
          // sorting={true}
          summary={true}
          grouping={true}
          groupPaging={true}
        />
        <Paging defaultPageSize={5} />
        <Scrolling columnRenderingMode={"virtual"} rowRenderingMode="virtual" />
        <ColumnFixing enabled={true} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={allowedPageSizes}
          showNavigationButtons={true}
          showInfo={true}
          visible={true}
        />
        <Toolbar>
          <ToolBarItem name="addRowButton" />
          <ToolBarItem location="after">
            <Button
              icon="refresh"
              onClick={() => {
                dataSource.reload();
              }}
            />
          </ToolBarItem>
        </Toolbar>
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}

          // allowDeleting={true}
        >
          <Popup
            title="Events Info"
            showTitle={true}
            width={700}
            height={525}
          />
          <Form ref={fileUploaderRef}>
            <Item itemType="group" colCount={2} colSpan={2}>
              <SimpleItem dataField="title" isRequired />
              <SimpleItem dataField="venue" />

              <SimpleItem dataField="organisedBy" />
              <SimpleItem dataField="contact" />

              <SimpleItem dataField="eventStart" />
              <SimpleItem dataField="eventEnd" />
            </Item>

            <SimpleItem
              dataField="description"
              editorType={"dxTextArea"}
              colSpan={2}
            />

            <SimpleItem dataField="postStart" isRequired />
            <SimpleItem dataField="postEnd" isRequired />

            <SimpleItem dataField="image" colSpan={2} />

            <SimpleItem dataField="postedBy" colSpan={2} isRequired />

            <SimpleItem dataField="postedContact" colSpan={2} isRequired />

            <SimpleItem
              dataField="isActive"
              helpText={"Make an Event Inactive"}
            />

            <SimpleItem
              dataField="category"
              editorType="dxSelectBox"
              editorOptions={categoryEditorOptions}
              isRequired
            />

            {/* <FileUploader multiple={false} selectButtonText="Select photo"
                            labelText="" accept="image/*"
                            uploadMode="useForm"


                            // encType="multipart/form-data"

                            // uploadFile={
                            //     async (e) => {
                            //         await uploadImage(cellInfo.data.id, e);
                            //     }}
                            // onUploadError={(e) => {
                            //     toast.error(e.message);
                            // }}
                            // onUploaded={(e) => {
                            //     toast.success("Image uploaded successfully");
                            // }}
                            invalidFileExtensionMessage="Only images are allowed"
                        /> */}
          </Form>
        </Editing>

        <Column dataField="title" />
        <Column dataField="description" />
        <Column
          dataField="image"
          width={100}
          cellRender={(cellInfo) => {
            return (
              <a
                href="#image-modal1"
                onClick={() => {
                  dispatch(setImageLink(cellInfo.data.images));
                }}
              >
                {cellInfo.data.images.length ? (
                  <p className="link link-primary">View Images</p>
                ) : null}

                {/* <RenderImage imageLocation={cellInfo.data.image} imageAlt={cellInfo.data.title} /> */}
              </a>
              // <label
              //   onClick={(e) => {
              //     e.preventDefault();
              //     // setImages(cellInfo.data.images);
              //     x = [...cellInfo.data.images];
              //     console.log(x);
              //   }}
              //   htmlFor="my-modal-5"
              //   className="cursor-pointer"
              // >
              //   <AiOutlinePlusSquare size={35} />
              // </label>
            );

            // <figure className='w-sm' >

            // </figure>
          }}
          editCellRender={editCellRender}
        />
        <Column dataField="category" />
        <Column dataField="postedBy" />
        <Column dataField="postedContact" />
        <Column
          dataField="eventStart"
          dataType="datetime"
          format={"dd/MM/yyyy hh:mm aa"}
        />
        <Column
          dataField="eventEnd"
          dataType="datetime"
          format={"dd/MM/yyyy hh:mm aa"}
        />
        <Column dataField="venue" />
        <Column dataField="organisedBy" />
        <Column dataField="contact" />
        <Column
          dataField="postStart"
          dataType="datetime"
          format={"dd/MM/yyyy hh:mm aa"}
        />
        <Column
          dataField="postEnd"
          dataType="datetime"
          format={"dd/MM/yyyy hh:mm aa"}
        />
        <Column
          dataField="isActive"
          dataType="boolean"
          cellRender={({ value }) => {
            return value ? (
              <span className="badge badge-success">Active</span>
            ) : (
              <span className="badge badge-error">Inactive</span>
            );
          }}
        />
        <Column
          dataField="createdAt"
          dataType="datetime"
          format={"dd/MM/yyyy hh:mm aa"}
          allowEditing={false}
        />
        <Column
          dataField="updatedAt"
          dataType="datetime"
          format={"dd/MM/yyyy hh:mm aa"}
          allowEditing={false}
        />
        <Summary>
          <TotalItem
            column="title"
            summaryType="count"
            displayFormat="Total {0} events"
          />
        </Summary>
      </DataGrid>
    </>
  );
};

export default EventsTable;
