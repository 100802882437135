import axios from "axios";
import { formatRFC3339 } from "date-fns/esm";
import React, { useEffect, useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import { toast } from "react-toastify";
import { RenderProfileImage } from "../components/utils/RenderImage";

function Team() {
  const [form, setForm] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [url, setUrl] = useState({ url: "", file: null });

  const [data, setData] = useState<any>([]);

  const onChange = (name: any, value: any) => {
    setForm({ ...form, [name]: value });
    if (value) {
      setErrors({ ...errors, [name]: null });
    } else {
      setErrors({ ...errors, [name]: "This is required." });
    }
  };

  const UpdateImage = (e: any) => {
    const files = e.target.files;
    if (files && files[0]) {
      uploadImage(files[0]);
      setUrl({ url: window.URL.createObjectURL(files[0]), file: files[0] });
    }
  };

  const uploadImage = (file: any) => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");

    const fd = new FormData();
    fd.append("file", file);
    axios
      .post(`${baseUrl}uploadImage/${"team"}`, fd, {
        headers: {
          authorization: token ? token : "",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setForm({ ...form, photo: res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    if (!form.name || !form.name.length) {
      return setErrors({
        ...errors,
        name: "Please enter name",
      });
    }
    if (!form.mobile || form.mobile.length !==10) {
      return setErrors({
        ...errors,
        mobile: "Please enter mobile",
      });
    }
    if (!form.area || !form.area.length) {
      return setErrors({
        ...errors,
        mobile: "Please enter area",
      });
    }
    if (!form.native || !form.native.length) {
      return setErrors({
        ...errors,
        mobile: "Please enter native",
      });
    }

    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");

    axios
      .post(
        `${baseUrl}team/add`,
        {
          photo: form.photo ? form.photo : null,
          mobile: form.mobile,
          name: form.name,
          native: form.native,
          area: form.area,
        },
        {
          headers: {
            authorization: token ? token : "",
          },
        }
      )
      .then((res) => {
        toast.success("Team member added.");
        window.location.reload();
      })
      .catch((err) => {
        toast.error("Add team member failed.");
      });
  };

  const handleGetTeam = () => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");

    axios
      .get(`${baseUrl}team`, {
        headers: {
          authorization: token ? token : "",
        },
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetTeam();

    return () => {};
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <label htmlFor="my-modal-5" className="cursor-pointer">
          <AiOutlinePlusSquare size={35} />
        </label>
      </div>

      <div>
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box w-11/12 max-w-5xl">
            <div className="modal-action">
              <label htmlFor="my-modal-5" className="btn">
                Close
              </label>
            </div>
            <div>
              <div>
                <div className="flex items-center justify-center w-full">
                  <label className="flex flex-col w-50 h-50 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                    <div className="flex flex-col items-center justify-center pt-7">
                      {url.url ? (
                        <div className="w-auto">
                          <img
                            src={url.url}
                            style={{
                              width: "250px",
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              //   fill-rule="evenodd"
                              d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                              //   clip-rule="evenodd"
                            />
                          </svg>
                          <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                            Select a photo
                          </p>
                        </>
                      )}
                    </div>
                    <input
                      onChange={UpdateImage}
                      type="file"
                      className="opacity-0"
                      accept="image/*"
                    />
                  </label>
                </div>

                <div className="flex justify-center flex-wrap">
                  <div className="stats w-96 shadow-xl m-2">
                    <div className="stat">
                      <div className="stat-title">Name</div>
                      <div className="stat-value text-2xl">
                        <input
                          type="text"
                          className="input input-bordered"
                          onChange={(e: any) => {
                            onChange("name", e.target.value);
                          }}
                        />
                        {errors.name && (
                          <div className="text-base text-red-700">
                            {errors.name}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="stats w-96 shadow-xl m-2">
                    <div className="stat">
                      <div className="stat-title">Native</div>
                      <div className="stat-value text-2xl">
                        <input
                          type="text"
                          className="input input-bordered"
                          onChange={(e: any) => {
                            onChange("native", e.target.value);
                          }}
                        />
                        {errors.native && (
                          <div className="text-base text-red-700">
                            {errors.native}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="stats w-96 shadow-xl m-2">
                    <div className="stat">
                      <div className="stat-title">Area</div>
                      <div className="stat-value text-2xl">
                        <input
                          type="text"
                          className="input input-bordered"
                          onChange={(e: any) => {
                            onChange("area", e.target.value);
                          }}
                        />
                        {errors.area && (
                          <div className="text-base text-red-700">
                            {errors.area}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="stats w-96 shadow-xl m-2">
                    <div className="stat">
                      <div className="stat-title">Mobile</div>
                      <div className="stat-value text-2xl">
                        <input
                          type="text"
                          className="input input-bordered"
                          onChange={(e: any) => {
                            onChange("mobile", e.target.value);
                          }}
                        />
                        {errors.mobile && (
                          <div className="text-base text-red-700">
                            {errors.mobile}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="m-2 flex justify-center">
                  <button onClick={handleSubmit} className="btn">
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-center items-center">
        {data && data.length ? (
          data.map((data: any, key: any) => (
            <ProfileCard key={key} data={data} />
          ))
        ) : (
          <h1 className="text-center">No Team Members</h1>
        )}
      </div>
    </>
  );
}

type ProfileCardProps = {
  data: {
    id: string;
    photo: string;
    name: string;
    mobile: string;
    area: string;
    native: string;
  };
};

const ProfileCard = ({ data }: ProfileCardProps) => {
  const handleRemoveTeam = (id: string) => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");

    axios
      .post(
        `${baseUrl}team/remove/${id}`,
        {},
        {
          headers: {
            authorization: token ? token : "",
          },
        }
      )
      .then((res) => {
        toast.success("Removed successfully");
        window.location.reload();
      })
      .catch((err) => {
        toast.error("Removed Error");
      });
  };

  return (
    <div className="card overflow-clip bg-base-100 shadow-xl m-3">
      <figure className="p-2 w-32 m-auto">
        <RenderProfileImage
          imageLocation={data.photo}
          firstName={data.name}
          lastName={""}
        />
      </figure>
      <div className="card-body p-3">
        <h1>{data.name}</h1>
        <p>{data.mobile}</p>
        <p>
          <MdLocationOn /> {data.area} ({data.native})
        </p>
        <div className="card-actions justify-between items-center">
          <button
            onClick={() => handleRemoveTeam(data.id)}
            className="btn btn-sm btn-error m-auto mt-1"
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default Team;
