import { Navigate, Route, RouteProps, Outlet, useLocation } from 'react-router-dom'
import Footer from '../components/footer/footer';
import Navbar from '../components/navbar/navbar'
import { useAuth } from '../hooks/useAuth'
import Admin from '../pages/Admin';

export function PrivateRoute({ children, ...rest }: RouteProps) {
    const { isAuthenticated } = useAuth()
    const location = useLocation();
    return (
        isAuthenticated ?
            <div className='min-h-screen flex flex-col'>
                <Navbar />
                <div className='w-11/12 mx-auto mt-10'>
                    <Outlet />
                </div>
                <Footer />
            </div>
            : <Navigate state={{ from: location }} to="/login" replace />
        // <Route
        //     element={() =>
        //         isAuthenticated ? (
        //             children
        //         ) : (
        //             <Navigate to="/login" />
        //         )
        //     }
        // />
    )
}
