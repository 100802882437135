import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useLogoutMutation } from "../../app/services/auth";
import { useAppDispatch } from "../../app/store";
import { clearState } from "../../features/authSlice";
import { useAuth } from "../../hooks/useAuth";
import { NavbarRoutes } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import axios from "axios";
import { log } from "console";

type Props = {};

const Navbar = (props: Props) => {
  const { isAdmin, user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // get url params
  const location = useLocation();

  const [logout, { isLoading }] = useLogoutMutation();

  const [dummy, setDummy] = useState('')

  const signout = async () => {
    navigate("/login");
    dispatch(clearState());
    await logout({});
  };

  useEffect(() => {
    axios
      .get("https://randomuser.me/api/")
      .then((res:any) => {
        console.log(res.data.results[0].picture.medium);
        setDummy(res.data.results[0].picture.medium)
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="navbar bg-base-100 min-h-12">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 "
          >
            {NavbarRoutes.map((route, index) => {
              return (
                <li key={index}>
                  <Link to={route.path}>{route.label}</Link>
                </li>
              );
            })}

            {isAdmin ? (
              <li>
                <Link to="/admin">Admin</Link>
              </li>
            ) : null}
          </ul>
        </div>

        <Link to="/" className="btn btn-ghost">
          <img className="w-12" src={logo} alt="Prayas logo" />
          <h2>Prayas</h2>
        </Link>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal p-0">
          {NavbarRoutes.map((route, index) => {
            return (
              <li
                key={index}
                className={`${
                  location.pathname === route.path ? "bg-base-300" : ""
                } rounded-md`}
              >
                <Link to={route.path}>{route.label}</Link>
              </li>
            );
          })}
          {isAdmin ? (
            <li>
              <Link to="/admin">Admin</Link>
            </li>
          ) : null}
        </ul>
      </div>
      <div className="navbar-end">
        <h1 className="hidden lg:block badge badge-accent font-bold">
          {user?.email}
        </h1>
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <img
                // src="https://api.lorem.space/image/face?hash=33791"
                src={dummy}
                loading="lazy"
                alt="Profile Image"
              />
            </div>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
          >
            {/* <li>
                                <a className="justify-between">
                                    Profile
                                    <span className="badge">New</span>
                                </a>
                            </li>*/}
            <li>
              <h1 className="block lg:hidden justify-center">
                <span className="badge badge-accent"> {user?.email}</span>
              </h1>
            </li>
            <li>
              <a onClick={signout}>Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
