import axios from "axios";
import { Events } from "../../models/events";
import api from "../api";

const getAllEvents = (skip?: number, take?: number) => {
  return api({
    url: `/events?skip=${skip}&take=${take}`,
    method: "GET",
  });
};

const createEvents = (body: Events) => api.post("/event", body);

const updateEvents = (id: string, body: Events) =>
  api.put(`/event/${id}`, body);

const uploadImage = async (id: string, formData: any) => {
  const fd = new FormData();

  fd.append("file", formData);

  return api.post(`/event/${id}/upload-photo`, fd, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  // const baseUrl = process.env.REACT_APP_API;
  // const token = localStorage.getItem("token");

  // await axios.post(`${baseUrl}event/${id}/upload-photo`, fd, {
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //     authorization: token ? token : "",
  //   },
  // });
};

export { getAllEvents, createEvents, updateEvents, uploadImage };
