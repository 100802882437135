import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetUserQuery } from "../app/services/userData";
import { Country, State, City } from "country-state-city";
import { ZonesData } from "../DropData";
import { toast } from "react-toastify";

type formData = {
  id: any;
  profilePhoto: any;
  coverPhoto: any;
  firstName: any;
  lastName: any;
  gender: any;
  dob: any;
  bio: any;
  anniversary: any;
  email: any;
  mobile: any;
  occupation: any;
  qualification: any;
  hobbies: any;
  landline: any;
  bloodGroup: any;
  isDonor: any;
  addressLine: any;
  city: any;
  area: any;
  state: any;
  country: any;
  pincode: any;
  officeAddress: any;
  officeCity: any;
  officeState: any;
  officeCountry: any;
  officePincode: any;
  isHeadOfHousehold: any;
  nativeVillageId: any;
  newNativeVillage: any;
  guruJi: any;
  maritalStatus: any;
  fatherId: any;
  motherId: any;
  spouseId: any;
  homeOwnerId: any;
  createdAt: any;
  updatedAt: any;
  verified: any;
  isActive: any;
  isDeceased: any;
  deceasedDate: any;
  showMobile: any;
  referralId: any;
  deleted: any;
  nativeVillage: any;
  father: any;
  mother: any;
  spouse: any;
};

function EditUser() {
  let params = useParams();
  const id = params.id;
  const location = useLocation();

  const { data, error, isLoading, isFetching } = useGetUserQuery(id!);

  const [form, setForm] = useState<any>({});

  let countries = Country.getAllCountries();

  const [code, setCode] = useState("IN");
  const [code1, setCode1] = useState("IN");

  const [area, setArea] = useState(form.area ? form.area : "");
  const [area1, setArea1] = useState(form.area ? form.area : "");

  const [states1, setStates1] = useState<any>([]);
  const [states2, setStates2] = useState<any>([]);

  const [zones, setZones] = useState<any | {}>(ZonesData);

  const [cities, setCities] = useState<any>([]);

  const [areas, setAreas] = useState<any>([]);
  const [areas1, setAreas1] = useState<any>([]);

  const [mobile, setMobile] = useState<any>();

  //   {
  //     "id": "25bec2de-da16-4e09-a17b-e46485e66490",
  //     "profilePhoto": any,
  //     "coverPhoto": any,
  //     "firstName": "Praveen",
  //     "lastName": "Bhatewara",
  //     "gender": "MALE",
  //     "dob": "1979-12-29T00:00:00.000Z",
  //     "bio": any,
  //     "anniversary": any,
  //     "email": any,
  //     "mobile": "+919845433512",
  //     "occupation": any,
  //     "qualification": any,
  //     "hobbies": any,
  //     "landline": any,
  //     "bloodGroup": any,
  //     "isDonor": any,
  //     "addressLine": any,
  //     "city": "Bangalore",
  //     "area": "Tyagrajnagar",
  //     "state": "Karnataka",
  //     "country": "India",
  //     "pincode": any,
  //     "officeAddress": any,
  //     "officeCity": any,
  //     "officeState": any,
  //     "officeCountry": any,
  //     "officePincode": any,
  //     "isHeadOfHousehold": false,
  //     "nativeVillageId": "9e86e214-1230-4964-ab82-0684baa4f2b6",
  //     "newNativeVillage": any,
  //     "guruJi": any,
  //     "maritalStatus": "MARRIED",
  //     "fatherId": any,
  //     "motherId": any,
  //     "spouseId": any,
  //     "homeOwnerId": any,
  //     "createdAt": "2022-12-16T11:02:51.936Z",
  //     "updatedAt": "2022-12-30T06:29:49.795Z",
  //     "verified": false,
  //     "isActive": true,
  //     "isDeceased": false,
  //     "deceasedDate": any,
  //     "showMobile": true,
  //     "referralId": any,
  //     "deleted": false,
  //     "nativeVillage": {
  //       "id": "9e86e214-1230-4964-ab82-0684baa4f2b6",
  //       "name": "Koshithal"
  //     },
  //     "father": any,
  //     "mother": any,
  //     "spouse": any
  //   }

  const onChange = (name: any, value: any) => {
    // if (value !== "") {
    //   if (name === "mobile") {
    //     var pattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

    //     if (pattern.test(value)) {
    //       setErrors((prev) => {
    //         return { ...prev, [name]: any };
    //       });
    //     } else {
    //       setErrors((prev) => {
    //         return { ...prev, [name]: "Please enter valid mobile number" };
    //       });
    //     }
    //   } else {
    //     setErrors((prev) => {
    //       return { ...prev, [name]: any };
    //     });
    //   }
    // } else {
    //   setErrors((prev) => {
    //     return { ...prev, [name]: "This field is required...." };
    //   });
    // }(

    if (
      name === "mobile" &&
      // typeof value === "number"
      // Number.isInteger(value)
      value.toString().length > 0 &&
      value.toString().length === 10
    ) {
      setForm({ ...form, [name]: value.toString() });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const updateUser = () => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");

    const newMobile =
      form.mobile.length === 10 ? "+91" + form.mobile : null;

    const { nativeVillage, father, mother, spouse, ...newForm } = form;

    const finalForm = { ...newForm, mobile: newMobile };

    // console.log(newForm);
    // console.log(newMobile);
    // console.log(finalForm);

    axios
      .put(`${baseUrl}user/${id}`, finalForm, {
        headers: {
          authorization: token ? token : "",
        },
      })
      .then((res) => {
        toast.success("User updated successfully.");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error in updating user.");
      });
  };

  const getData = () => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");
    axios
      .get(`${baseUrl}user/${id}`, {
        headers: {
          authorization: token ? token : "",
        },
      })
      .then((res) => {
        const mob =
          res.data.data.mobile && res.data.data.mobile.length
            ? res.data.data.mobile
            : "";
        setForm({
          ...form,
          ...res.data.data,
          mobile: mob,
        });
        setMobile(mob);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCities = () => {
    const data = Object.keys(ZonesData);

    const x: any = [];

    data.forEach((e) => {
      const a = e.toLowerCase();
      x.push(a[0].toUpperCase() + a.slice(1));
    });
    setCities(x);
  };

  const getAreas = (name: any, val: any) => {
    const data: any = zones[val.toUpperCase()];
    const x: any = [];
    // console.log(data);
    if (data !== undefined) {
      data.forEach((e: any) => {
        const a = e.toLowerCase();
        x.push(a[0].toUpperCase() + a.slice(1));
      });

      if (name === "area") {
        setAreas(x);
      }
      if (name === "area1") {
        setAreas1(x);
      }
    }
  };

  useEffect(() => {
    const states = State.getStatesOfCountry(code);
    setStates1(states);
  }, [code]);

  useEffect(() => {
    const states = State.getStatesOfCountry(code1);
    setStates2(states);
  }, [code1]);

  useEffect(() => {
    getData();

    return () => {};
  }, [id]);

  useEffect(() => {
    getCities();
    return () => {};
  }, []);

  useEffect(() => {
    if (data) {
      if (data.city) {
        getAreas("area", data.city);
      }
    }
    return () => {};
  }, [data]);

  return (
    <>
      {form ? (
        <div>
          <div className="flex justify-center flex-wrap">
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Firts Name</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.firstName}
                    type="text"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("firstName", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Last Name</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.lastName}
                    type="text"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("lastName", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Email</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.email}
                    type="email"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("email", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Mobile</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.mobile}
                    type="tel"
                    placeholder="Mobile number without +91"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("mobile", e.target.value);
                    }}
                    disabled={
                      mobile && mobile.length && mobile.length >= 12
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Gender</div>
                <div className="stat-value text-2xl">
                  <select
                    value={form.gender ? form.gender : "-"}
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("gender", e.target.value);
                    }}
                  >
                    <option value="-">Select</option>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">DOB</div>
                <div className="stat-value text-2xl">
                  <input
                    value={
                      form.dob
                        ? new Date(form.dob).getFullYear() +
                          "-" +
                          (new Date(form.dob).getMonth().toLocaleString() + 1) +
                          "-" +
                          new Date(form.dob).getDate()
                        : ""
                    }
                    type="date"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      const date = e.target.value;
                      onChange("dob", new Date(date));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Landline</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.landline}
                    type="text"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("landline", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Occupation</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.occupation}
                    type="text"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("occupation", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Qualification</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.qualification}
                    type="text"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("qualification", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Marital Status</div>
                <div className="stat-value text-2xl">
                  <select
                    value={form.maritalStatus ? form.maritalStatus : "-"}
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("maritalStatus", e.target.value);
                    }}
                  >
                    <option value="-">Select</option>
                    <option value="SINGLE">Single</option>
                    <option value="MARRIED">Married</option>
                    <option value="DIVORCED">Divorced</option>
                    <option value="WIDOWED">Widowed</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Is Donor</div>
                <div className="stat-value text-2xl">
                  <input
                    checked={form.isDonor ? form.isDonor : false}
                    type="checkbox"
                    onChange={(e: any) => {
                      onChange("isDonor", e.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="divider font-bold">Address</div>

          <div className="flex justify-center flex-wrap">
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Country</div>
                <div className="stat-value text-2xl">
                  <select
                    value={form.country ? form.country : "-"}
                    className="input input-bordered"
                    onChange={(e: any) => {
                      const val = e.target.value;
                      onChange("country", val);
                      countries.forEach((e) => {
                        if (e.name === val) {
                          setCode(e.isoCode);
                        }
                      });
                    }}
                  >
                    <option value={"-"}>Select</option>
                    {countries.map((data, key) => (
                      <option key={key} value={data.name}>
                        {data.flag}&nbsp;&nbsp;{data.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">State</div>
                <div className="stat-value text-2xl">
                  <select
                    value={form.state ? form.state : "-"}
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("state", e.target.value);
                    }}
                  >
                    <option value="-">Select</option>
                    {states1.map((data: any, key: any) => (
                      <option key={key} value={data.name}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">City</div>
                <div className="stat-value text-2xl">
                  <select
                    value={form.city ? form.city : "-"}
                    className="input input-bordered"
                    onChange={(e: any) => {
                      getAreas("area", e.target.value);
                      onChange("city", e.target.value);
                    }}
                  >
                    <option value="-">Select</option>
                    {cities.map((data: any, key: any) => (
                      <option key={key} value={data}>
                        {data}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Area</div>
                <div className="stat-value text-2xl">
                  <select
                    value={form.area ? form.area : "-"}
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("area", e.target.value);
                    }}
                  >
                    <option value="-">Select</option>
                    {areas.map((data: any, key: any) => (
                      <option key={key} value={data}>
                        {data}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Address Line</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.addressLine}
                    type="text"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("addressLine", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Pincode</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.pincode}
                    type="text"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("pincode", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* By Vaagish */}

          <div className="divider font-bold">Additional Details</div>
          <div className="flex justify-center flex-wrap">
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Anniversary</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.anniversary}
                    type="text"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("anniversary", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Bio</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.bio}
                    type="text"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("bio", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Hobbies</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.hobbies}
                    type="text"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("hobbies", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Blood Group</div>
                <div className="stat-value text-2xl">
                  <select
                    value={form.bloodGroup ? form.bloodGroup : "-"}
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("bloodGroup", e.target.value);
                    }}
                  >
                    <option value="-">Select</option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Guru Ji</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.guruJi}
                    type="text"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("guruJi", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="divider font-bold">Office Address</div>

          <div className="flex justify-center flex-wrap">
            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Office Country</div>
                <div className="stat-value text-2xl">
                  <select
                    value={form.officeCountry ? form.officeCountry : "-"}
                    className="input input-bordered"
                    onChange={(e: any) => {
                      const val = e.target.value;
                      onChange("officeCountry", val);
                      countries.forEach((e) => {
                        if (e.name === val) {
                          setCode1(e.isoCode);
                        }
                      });
                    }}
                  >
                    <option value={"-"}>Select</option>
                    {countries.map((data, key) => (
                      <option key={key} value={data.name}>
                        {data.flag}&nbsp;&nbsp;{data.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Office State</div>
                <div className="stat-value text-2xl">
                  <select
                    value={form.state ? form.state : "-"}
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("officeState", e.target.value);
                    }}
                  >
                    <option value={"-"}>Select</option>
                    {states2.map((data: any, key: any) => (
                      <option key={key} value={data.name}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Office City</div>
                <div className="stat-value text-2xl">
                  <select
                    defaultValue={"-"}
                    className="input input-bordered"
                    onChange={(e: any) => {
                      getAreas("area1", e.target.value);
                      onChange("officeCity", e.target.value);
                    }}
                  >
                    <option value="-">Select</option>
                    {cities.map((data: any, key: any) => (
                      <option key={key} value={data}>
                        {data}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Office Address</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.officeAddress}
                    type="text"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("officeAddress", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="stats w-96 shadow-xl m-2">
              <div className="stat">
                <div className="stat-title">Office Pincode</div>
                <div className="stat-value text-2xl">
                  <input
                    value={form.officePincode}
                    type="text"
                    className="input input-bordered"
                    onChange={(e: any) => {
                      onChange("officePincode", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="m-2 flex justify-center">
            <button
              onClick={() => {
                updateUser();
              }}
              className="btn"
            >
              SUBMIT
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default EditUser;
