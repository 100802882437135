import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetAllNativesQuery } from "../../app/services/natives";
import { nativeCount } from "../../utils/constants";
import { getNativeName } from "../../utils/utils";


function Index() {
  const params = useParams();
  const [selectedMembers, setSelectedMembers] = useState<any>([]);

  const getMembers = () => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");
    axios
      .post(
        `${baseUrl}selectedUserMembers/${params.id}`,
        {},
        {
          headers: {
            authorization: token ? token : "",
          },
        }
      )
      .then((res) => {
        setSelectedMembers(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    getMembers();
    return () => {};
  }, [params.id]);

  return (
    <div className="flex flex-wrap justify-center items-center">
      {selectedMembers && selectedMembers.length ? (
        selectedMembers.map((user: any, key: any) => (
          <ProfileCard data={user} key={key} />
        ))
      ) : (
        <h1 className="text-cenetr m-10">No members found.</h1>
      )}
    </div>
  );
}

type ProfileCardProps = {
  data: {
    id: string;
    profilePhoto: string;
    firstName: string;
    lastName: string;
    mobile: string;
    city: string;
    state: string;
    addressLine: string;
    nativeVillageId: string;
    doc: string;
  };
};

const ProfileCard = ({ data }: ProfileCardProps) => {
  const natives = useGetAllNativesQuery({ take: nativeCount, skip: 0 });
  const params = useParams();

  const removeMember = (doc: string) => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");
    axios
      .post(
        `${baseUrl}selectedMembers/remove/${doc}`,
        {},
        {
          headers: {
            authorization: token ? token : "",
          },
        }
      )
      .then((res) => {
        toast.success("User added Successfully");
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <>
      <div>
        <div
          // href="#"
          className="flex mx-auto my-10 mx-5 items-center justify-center bg-white border border-white-200 rounded-lg shadow md:flex-row md:max-w-xl cursor-pointer"
        >
          <div className="flex flex-col justify-between p-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight">
              {data.firstName} {data.lastName}
            </h5>
            <p className="flex justify-between mb-3 mr-10 font-normal">
              <span className="mr-10">{data.mobile}</span>
              {/* <span>({data.city})</span> */}
              <span>
                {data.nativeVillageId
                  ? "(" +
                    getNativeName(data.nativeVillageId, natives?.data) +
                    ")"
                  : ""}
              </span>
            </p>
            <p className="mb-3 font-normal">
              {data.addressLine}, {data.city}, {data.state}.
            </p>
            <button
              type="button"
              className="text-white font-medium rounded-full text-sm p-1 text-center inline-flex items-center mr-2 bg-orange-600 w-8"
              onClick={() => {
                removeMember(data.doc);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>

              <span className="sr-only">Icon description</span>
            </button>
          </div>
          <img
            className="object-cover w-full rounded-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-lg"
            src={
              data.profilePhoto
                ? data.profilePhoto
                : `https://avatars.dicebear.com/api/initials/${
                    data.firstName + " " + data.lastName
                  }.png`
            }
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default Index;
