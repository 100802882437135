import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

function Banner() {
  const [banner, setBanner] = useState({ url: "" });
  const [url, setUrl] = useState({ url: "", file: null });

  const UpdateBanner = (e: any) => {
    const files = e.target.files;
    if (files && files[0]) {
      setUrl({ url: window.URL.createObjectURL(files[0]), file: files[0] });
    }
  };

  const getBanner = () => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");

    axios
      .get(`${baseUrl}banner`, {
        headers: {
          authorization: token ? token : "",
        },
      })
      .then((res) => {        
        setBanner({ url: res.data.data.image });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadBanner = () => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");

    if (url.file) {
      const fd = new FormData();
      fd.append("file", url.file);

      axios
        .post(`${baseUrl}bannerUpload`, fd, {
          headers: {
            authorization: token ? token : "",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success("Successfully Updated");
          window.location.reload()
        })
        .catch((err) => {
          toast.error("Failed to update");
        });
    }
  };

  useEffect(() => {
    getBanner();
    return () => {};
  }, []);

  return (
    <div>
      <div className="flex justify-center mt-8 flex-wrap">
        {banner && banner.url ? (
          <div
            style={{
              width: "50%",
              height: "auto",
            }}
          >
            <img
              src={process.env.REACT_APP_S3_URI + banner.url}
              className="rounded w-full h-full"
            />
          </div>
        ) : null}
        <div className="rounded-lg shadow-xl bg-gray-50 lg:w-1/2">
          <div className="m-4">
            <label className="inline-block mb-2 text-gray-500">
              Upload Image(jpg,png,jpeg)
            </label>
            <div className="flex items-center justify-center w-full">
              <label className="flex flex-col w-full h-50 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                <div className="flex flex-col items-center justify-center pt-7">
                  {url.url ? (
                    <div className="w-100">
                      <img src={url.url} className="w-full h-full" />
                    </div>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          //   fill-rule="evenodd"
                          d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                          //   clip-rule="evenodd"
                        />
                      </svg>
                      <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                        Select a photo
                      </p>
                    </>
                  )}
                </div>
                <input
                  onChange={UpdateBanner}
                  type="file"
                  className="opacity-0"
                  accept="image/*"
                />
              </label>
            </div>
          </div>
          <div className="flex p-2 space-x-4 justify-center">
            {/* <button className="px-4 py-2 text-white bg-red-500 rounded shadow-xl">
              Cannel
            </button> */}
            <button
              onClick={() => uploadBanner()}
              className="px-4 py-2 text-white bg-primary rounded shadow-xl"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
