import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ImageModalState = {
    imageLink: any | null
}

const initialState: ImageModalState = {
    imageLink: null
};

const imageModal = createSlice({
    name: 'imageModal',
    initialState: initialState,
    reducers: {
        setImageLink: (state: { imageLink: any; }, action: { payload: any; }) => {
            state.imageLink = action.payload;
        },
    },
})

export const { setImageLink } = imageModal.actions

export default imageModal.reducer


