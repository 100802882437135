import axios from "axios";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useGetUserQuery } from "../app/services/userData";
import SearchBar from "../components/user/searchBar";

type Props = {};

const Search = (props: Props) => {
  // const [searchUserId, setSearchUserId] = React.useState('');
  // const { data, error, isLoading } = useGetUserQuery(searchUserId);

  // useEffect(() => {

  //   const baseUrl = process.env.REACT_APP_API;
  //   const token = localStorage.getItem("token");

  //   axios
  //     .get(
  //       `${baseUrl}event/byCategory/Sports`,
  //       {
  //         headers: {
  //           authorization: token ? token : "",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });

  //   return () => {};
  // }, []);

  return <SearchBar />;
};

export default Search;
