import { ErrorMessage } from "@hookform/error-message";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { GoVerified } from "react-icons/go";
import { MdLocationOn } from "react-icons/md";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetAllNativesQuery } from "../../app/services/natives";
import {
  useAddChildMutation,
  useAddFatherMutation,
  useAddMotherMutation,
  useAddSiblingsMutation,
  useAddSpouseMutation,
  useSearchUsersQuery,
  useSearchActiveUsersQuery,
} from "../../app/services/userData";
import { ZonesData } from "../../DropData";
import { Gender } from "../../models/profiles";
import { nativeCount } from "../../utils/constants";
import { getNativeName } from "../../utils/utils";
import Selector from "../utils/DropDownSearch";
import Loading from "../utils/Loading";
import Pagination from "../utils/Pagination";
import { RenderProfileImage } from "../utils/RenderImage";

type Props = {
  relation: string;
  id: string;
};

const AddRelationSearch = (props: Props) => {
  const { data } = useGetAllNativesQuery({ take: nativeCount, skip: 0 });

  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    city: "",
    area: "",
  });

  const [data1, setData1] = useState([]);

  const [cities, setCities] = useState<any | []>([]);
  const [areas, setAreas] = useState<any | []>([]);
  const [zones, setZones] = useState<any | {}>(ZonesData);

  const getData = () => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");

    if (filters.city.length && filters.area.length) {
      setLoading(true);

      const city = filters.city.toLowerCase();
      const area = filters.area.toLowerCase();

      axios
        .post(
          `${baseUrl}search/allDirectory`,
          {
            city: city.charAt(0).toUpperCase() + city.slice(1),
            area: area.charAt(0).toUpperCase() + area.slice(1),
          },
          {
            headers: {
              authorization: token ? token : "",
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setData1(res.data.data);
          console.log(res.data.data);
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
        });
    }
  };

  const getCities = () => {
    setCities(Object.keys(ZonesData));
  };

  const getAreas = () => {
    setAreas(zones[filters.city]);
  };

  useEffect(() => {
    getCities();
    return () => {};
  }, []);

  useEffect(() => {
    getAreas();
    getData();
    return () => {};
  }, [filters]);

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const lastUserIndex = currentPage * usersPerPage;
  const firstUserIndex = lastUserIndex - usersPerPage;
  const data2 = data1 ? data1.slice(firstUserIndex, lastUserIndex) : [];

  return (
    <>
      <div
        className="font-Poppins flex items-center justify-center flex-wrap"
        style={{
          zIndex: 100,
        }}
      >
        <Selector
          setFilters={setFilters}
          filters={filters}
          name="Zone"
          data={cities}
        />
        <Selector
          setFilters={setFilters}
          filters={filters}
          name="Area"
          data={areas && areas.length ? areas : []}
        />
      </div>

      {loading ? <Loading /> : null}
      <div className="flex flex-wrap justify-center items-center">
        {data2 && data2.length !== 0 ? (
          data2.map((user: any) => (
            <ProfileCard
              key={user.id}
              profileData={user}
              relation={props.relation}
              id={props.id}
            />
          ))
        ) : (
          <p className="text-md italic font-bold">--- no user found---</p>
        )}
      </div>

      <Pagination
        total={data1 ? data1.length : 0}
        usersPerPage={usersPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
};

type ProfileCardProps = {
  relation: string;
  id: string;
  profileData: {
    id: string;
    profilePhoto: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    nativeVillageId: string;
    verified: boolean;
    isDeceased: boolean;
  };
};

const ProfileCard = ({ profileData, relation, id }: ProfileCardProps) => {
  const natives = useGetAllNativesQuery({ take: nativeCount, skip: 0 });

  const [addChild] = useAddChildMutation();
  const [addSibling] = useAddSiblingsMutation();

  const [addFather] = useAddFatherMutation();
  const [addMother] = useAddMotherMutation();
  const [addSpouse] = useAddSpouseMutation();

  return (
    <div className="card card-compact w-40 bg-base-100 shadow-xl m-3">
      <figure className="p-2 w-32 m-auto">
        <RenderProfileImage
          imageLocation={profileData.profilePhoto}
          firstName={profileData.firstName}
          lastName={profileData.lastName}
        />
      </figure>
      <div className="card-body p-3">
        {/* <h2 className="card-title m-auto">{profileData.firstName} {profileData.lastName}</h2> */}
        <h2 className="flex items-center">
          {profileData.isDeceased ? "(late)" : null} {profileData.firstName}{" "}
          {profileData.lastName}&nbsp;
          {profileData.verified && (
            <GoVerified size={15} className="rounded-full text-blue-600" />
          )}{" "}
        </h2>
        {profileData.nativeVillageId ? (
          <p className="flex items-center">
            <MdLocationOn />{" "}
            {getNativeName(profileData.nativeVillageId, natives?.data)}
          </p>
        ) : null}

        <button
          className="card-actions justify-between items-center btn btn-sm"
          onClick={() => {
            switch (relation) {
              case "child":
                addChild({ id: id, childId: profileData.id })
                  .unwrap()
                  .then(() => {
                    toast.success("Added Child");
                  });
                break;
              case "sibling":
                addSibling({ id: id, siblingId: profileData.id })
                  .unwrap()
                  .then(() => {
                    toast.success("Added Sibling");
                  });
                break;
              case "father":
                addFather({ id: id, fatherId: profileData.id })
                  .unwrap()
                  .then(() => {
                    toast.success("Added Father");
                  });
                break;
              case "mother":
                addMother({ id: id, motherId: profileData.id })
                  .unwrap()
                  .then(() => {
                    toast.success("Added Mother");
                  });
                break;
              case "spouse":
                addSpouse({ id: id, spouseId: profileData.id })
                  .unwrap()
                  .then(() => {
                    toast.success("Added Spouse");
                  });
                break;
              default:
                break;
            }
          }}
        >
          Add {relation}
        </button>
      </div>
    </div>
  );
};

export default AddRelationSearch;
