import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import { useGetAllNativesQuery } from "../../app/services/natives";
import { nativeCount } from "../../utils/constants";
import { getNativeName } from "../../utils/utils";

function PDF() {
  const componentRef: any = useRef();

  const params = useParams();
  const [selectedMembers, setSelectedMembers] = useState<any>([]);

  const getMembers = () => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");
    axios
      .post(
        `${baseUrl}selectedUserMembers/${params.id}`,
        {},
        {
          headers: {
            authorization: token ? token : "",
          },
        }
      )
      .then((res) => {
        setSelectedMembers(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    getMembers();
    return () => {};
  }, [params.id]);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button
            type="button"
            className="text-white font-medium rounded-full text-sm p-1 text-center inline-flex items-center mr-2 bg-orange-600 w-8"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
              />
            </svg>
          </button>
        )}
        content={() => componentRef.current}
      />
      <div
        style={{
          display: "none",
        }}
      >
        <div
          className="flex flex-wrap justify-between items-center"
          ref={componentRef}
        >
          {selectedMembers &&
            selectedMembers.length &&
            selectedMembers.map((user: any, key: any) => (
              <ProfileCard data={user} key={key} />
            ))}
        </div>
      </div>
    </>
  );
}

type ProfileCardProps = {
  data: {
    id: string;
    profilePhoto: string;
    firstName: string;
    lastName: string;
    mobile: string;
    city: string;
    nativeVillageId: string;
    state: string;
    addressLine: string;
    doc: string;
  };
};

const ProfileCard = ({ data }: ProfileCardProps) => {
  const natives = useGetAllNativesQuery({ take: nativeCount, skip: 0 });
  return (
    <>
      <div>
        {/* <div          
          className="flex items-center justify-center bg-white border border-white-200 rounded-lg shadow cursor-pointer m-5"
        >
          <div className="flex flex-col justify-between p-5 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight">
              {data.firstName} {data.lastName}
            </h5>
            <p className="flex justify-between mb-3 font-normal">
              <span>{data.mobile}</span>
              <span>
                {data.nativeVillageId
                  ? getNativeName(data.nativeVillageId, natives?.data)
                  : ""}
              </span>
            </p>
            <p className="mb-3 font-normal">
              {data.addressLine}, {data.city}, {data.state}.
            </p>
          </div>
          <img
            className="object-cover w-full rounded-lg h-40"
            src={
              data.profilePhoto
                ? data.profilePhoto
                : `https://avatars.dicebear.com/api/initials/${
                    data.firstName + " " + data.lastName
                  }.png`
            }
            alt=""
          />
        </div> */}
        <div className="flex mx-auto my-10 items-center justify-center bg-white border border-white-200 rounded-lg cursor-pointer">
          <div className="flex flex-col justify-between p-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight">
              {data.firstName} {data.lastName}
            </h5>
            <p className="flex justify-between mb-3 font-normal">
              <span className="mr-5">{data.mobile}</span>
              <span>
                {data.nativeVillageId ? (
                  "(" + getNativeName(data.nativeVillageId, natives?.data) + ")"
                ) : (
                  <span className="text-white">{data.city}</span>
                )}
              </span>
            </p>
            <p className="mb-3 font-normal">
              {data.addressLine}, {data.city}, {data.state}.
            </p>
          </div>
          <img
            className="object-cover w-full rounded-lg h-40"
            src={
              data.profilePhoto
                ? data.profilePhoto
                : `https://avatars.dicebear.com/api/initials/${
                    data.firstName + " " + data.lastName
                  }.png`
            }
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default PDF;
