export const DropData = {
  state: [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
    "Other",
  ],

  city: [
    "ANDRA PRADESH/TELENGANA",
    "GOA",
    "AHMEDABAD/ GANDHINAGAR",
    "GUJRAT NORTH ZONE",
    "SURAT",
    "GUJRAT SOUTH ZONE",
    "BANGALORE",
    "MYSORE ZONE",
    "HUBLI ZONE",
    "KERALA",
    "MUMBAI",
    "KOKAN ZONE",
    "AJMER",
    "BHILWARA",
    "CHITTORGARH",
    "RAJSAMAND",
    "UDAIPUR",
    "MYSURU",
    "OTHER",
    "VIDESH",
  ],

  village: [
    "HYDRABAD/SECUNDRABAD",
    "MADGAON",
    "SHAHIBAG",
    "MANINAGAR",
    "SHANTINAGAR,USMANPURA,VAIS",
    "GHATLODIYA,CHANDLODIYA,NAVRANGPURA",
    "BAPUNAGAR,ODHAV,VASTRAPUR",
    "MEMNAGAR,SETELITE,BODAKDEV",
    "SUBHASBRIJ,SABARMATI,RANIP",
    "KHEDA,ASHLALI,JETALPUR,DASHKOY",
    "OTHER AREA IN AHMEDABAD",
    "AAKEDI",
    "AMBAJI",
    "BADOLI",
    "BAYAD",
    "BHILODA",
    "CHOILA",
    "DANTA",
    "DELVAD",
    "DEMAI",
    "DHANSURA",
    "GABAT",
    "GOJARIYA",
    "GRAMBHARATI",
    "HINMMATNAGAR",
    "IDER",
    "KADPAVANJ",
    "KHERANCHA",
    "KHEDBRHMA",
    "KUKERWADA",
    "LIMBDIYA",
    "LUNAVADA",
    "MEHSANA",
    "MALEKPUR",
    "MALPUR",
    "MANSA",
    "MEGHRAJ",
    "MEMADPUR",
    "MODASA",
    "PALANPUR",
    "PANDARWADA",
    "PARBATPURA",
    "PATAN",
    "POSHINA",
    "PRANTIJ",
    "RANASHAN",
    "SATHAMBA",
    "SIDHPUR",
    "SHIMALVADA",
    "UBHRAN",
    "UNAVA",
    "VADALI",
    "VADGAUM",
    "VIJAPUR",
    "VIRPUR",
    "ADAJAN-PAL",
    "ALTHAN-BHATAR ROAD",
    "AMROLI",
    "ANKLESHWAR-ILAV",
    "ASHVINIKUMAR ROAD",
    "BARDOLI",
    "BRODA PRISTAG-TAPI BAGH",
    "BHESTAN",
    "CITY LIGHT-BHATAR ROAD",
    "AMBAJI-KAILSH NGR-SANGRAMPURA-MAJURA GATE",
    "HIRABAGH-KAPODRA",
    "KADODARA-CHANTHAN-PALSANA",
    "KAMREJ",
    "KATARGAM",
    "KAVAS-HAJIRA-ICHAPUR-MORA",
    "KIM",
    "MAROLI",
    "NANA VARACHA",
    "NAVAGAM-DINDOLI",
    "OLPAD",
    "PANDESARA",
    "PARVAT PATIYA",
    "PUNNAGAM",
    "RANDER",
    "SACJIN-TALANGPUR",
    "SAYAN",
    "SUMUL DAIRY ROAD",
    "TRIKAM NAGAR-MATAWADI",
    "UDHNA",
    "UDHANAYARD (LIMBAYAT)-GODADARA",
    "VEDROAD",
    "VIP ROAD (BHATAR ROAD)",
    "VYARA-BAJIPUR-MANDVI",
    "VADODARA",
    "AARAK SISODRA",
    "ABRAMA",
    "AMADPORE",
    "AMARI",
    "AMBADA",
    "ATGAM",
    "BAYAD",
    "BILIMORA",
    "BODLAI",
    "CHIKHALI",
    "DHAMAN",
    "DHANORI",
    "DUNGARI",
    "GABAT",
    "GORGAM",
    "GREED",
    "GUNDLAV",
    "HARI",
    "IDAR",
    "KABILPORE",
    "KALIYAWADI",
    "KALVADA",
    "KANJANHARI",
    "KILLA PARADI",
    "VAPI",
    "KHEDA",
    "KHOKHARA",
    "MANSA",
    "MAROLI",
    "MEGHRAJ",
    "MODASA",
    "NADIYAD",
    "NAVSARI",
    "PALANPUR",
    "PARTHAN",
    "PEETHA",
    "PETHAPUR",
    "RONVEL",
    "SANDALPORE",
    "SATHANMBA",
    "SELVASVA",
    "SISODRA",
    "TEEGARA",
    "UGAT",
    "VADALI",
    "VAGALDHRA",
    "VALSAD",
    "VESMA",
    "VIJALPORE",
    "A. DASARHALLI",
    "ATTIGUPPE",
    "BAPUJINAGAR",
    "BASAVESHWARANAGAR",
    "BOMMANHALLI",
    "CHAMRAJPET",
    "CHANDRA LAY OUT",
    "CHOLURPALYA",
    "CITY",
    "GANGAMMA CIRCLE",
    "GANGANAGAR",
    "GOKULA",
    "GORAGUNTEPALYA",
    "HANUMANTHNAGAR",
    "HEBBAL",
    "HEGGANAHALLI",
    "JALAHALLI",
    "JAYANAGAR",
    "K.G.HALLI",
    "KAMAKSHIPALYA",
    "KATRIGUPPE",
    "KENGERI",
    "KURABARHALI.I",
    "LAGGERE",
    "MAGADI ROAD",
    "MAHALAXMI LAY OUT",
    "MALLESHWARAM",
    "MANJUNATH NAGAR",
    "MARIYAPPANPALYA",
    "PEENYA 2ND STAGE",
    "R R NAGAR",
    "RAJAJINAGAR",
    "RAMCHANDRAPURAM",
    "RPC LAYOUT",
    "SANJAYNAGAR",
    "SARAKKI",
    "SHIVAJI NAGAR",
    "SRINAGAR",
    "SRIRAMPURAM",
    "T.DASARAHALLI",
    "THYAGRAJNAGAR",
    "ULLAL",
    "VIDHYARANYAPURA",
    "VIJAYANAGAR",
    "VIJAYANAGAR-2",
    "WEST OF CHORD ROAD",
    "WILSON GARDEN",
    "YELAHANKA",
    "YESHWANTHPUR",
    "OTHER",
    "BELGAUM",
    "BELLARY",
    "BANNUR",
    "CHAMRAJNAGAR",
    "DAVANGERE",
    "GADAG",
    "HUBLI",
    "CHANPATANA",
    "CHANRAYAPATANA",
    "GUNDALPET",
    "H.D.KOTE",
    "HAND POST",
    "HUNSUR",
    "HASSAN",
    "HOLENARSIPUR",
    "MANDYA",
    "NANJANGUD",
    "PANDAVPURA",
    "PIRIYAPATANA",
    "SAKALESHPUR",
    "SARGUR",
    "SHRAVANBELGOLA",
    "SRIRANGPATANA",
    "T.NARSIPUR",
    "SIMOGA",
    "KOZHIKODE",
    "ERNAKULAM",
    "MUMBAI",
    "BIRWADI",
    "CHIPLUN",
    "CHOK",
    "DABHOL",
    "DAPOLI",
    "DASGAON",
    "GOREGAON",
    "KHED",
    "MAHAD",
    "MANDANGAON",
    "MANGAON",
    "MHALSA",
    "MHAPRAL",
    "RATNAGIRI",
    "REVDANDA",
    "VAKVALI",
    "AJMER",
    "TODGARH",
    "AMDALA",
    "AMLI",
    "ASIND",
    "BAGOLIYA",
    "BEMALI",
    "BHAGAWANPURA",
    "BHILWARA",
    "BHITANAGAR",
    "BORANA",
    "CHANKHED",
    "CHATAWATI",
    "CHAWANDIYA",
    "CHILESHWAR",
    "CHITAMBA",
    "DEVRIYA",
    "GAJUNA",
    "GANGAPUR",
    "GILDIYA",
    "GIRDIYA",
    "GODAS",
    "GORKHIYA",
    "GYANGARH",
    "JADAN",
    "JEERAN",
    "JHALARA",
    "KALAS",
    "KALIYAS",
    "KAMERI",
    "KAMERI CHOUHANO LI",
    "KAREDA",
    "KATAR",
    "KHAJURIYA",
    "KHEMANA",
    "KIDIMAL",
    "KOSHITHAL",
    "KUNDWA",
    "LADUVAS",
    "MANDAL",
    "MOKHUNDA",
    "NATHDIYAS",
    "NEGDIYA KA KHEDA",
    "PITHAS",
    "POTLA",
    "PUR",
    "RAIPUR",
    "RANAS",
    "SALERA",
    "SAMELIYA",
    "SHIVPUR",
    "SODAS",
    "THANA",
    "ULLAI",
    "UMRI",
    "MUROLI",
    "RASHMI",
    "REWADA",
    "AMET",
    "ANJANA",
    "ASAN",
    "BAGANA",
    "BAGGAD",
    "BARAR",
    "BARJAL",
    "BHIM",
    "CHAPALI",
    "CHARBHUJA ROAD",
    "DEOGARH",
    "DOLAJI KA KHEDA",
    "DOLPURA",
    "GHATAWAR",
    "GOGATHALA",
    "ISARMAND",
    "JUNADA",
    "KALADEH",
    "KALESARIYA",
    "KAMLIGHAT",
    "KANKROLI",
    "KELWA",
    "KHAKHARMALA",
    "KHARNOTA",
    "KUKARKHEDA",
    "KUNWARIYA",
    "KUNWATHAL",
    "LAKHAGUDA",
    "LASANI",
    "MADARIYA",
    "MANDAWAR",
    "NANANA",
    "NARANA",
    "NARDASJI KA GUDA",
    "NATHDWARA",
    "NEEMJAR",
    "PANOTIYA",
    "PEETHA KA KHEDA",
    "PIPALINAGAR",
    "RAJNAGAR",
    "RAJSAMAND",
    "SAKARAWAS",
    "SANGAWAS",
    "SARDARGARH",
    "SAREVADI",
    "SEVANTRI",
    "SOHANGARH",
    "TAL",
    "THIKARWAS",
    "UDAIPUR",
    "GANDHINAGAR",
    "MYSURU",
    "OTHER THEN LIST",
  ],

  "AHMEDABAD / GANDHINAGAR": [
    "SHAHIBAG",
    "MANINAGAR",
    "SHANTINAGAR-USMANPURA-VAIS",
    "GHATLODIYA-CHANDLODIYA-NAVRANGPURA",
    "BAPUNAGAR-ODHAV-VASTRAPUR",
    "MEMNAGAR-SETELITE-BODAKDEV",
    "SUBHASBRIJ-SABARMATI-RANIP",
    "KHEDA-ASHLALI-JETALPUR-DASHKOY",
    "OTHER AREA IN AHMEDABAD",
    "GANDHINAGAR",
  ],

  AJMER: ["AJMER", "TODGARH", "OTHER"],

  BANGALORE: [
    "A. DASARHALLI",
    "ATTIGUPPE",
    "BAPUJINAGAR",
    "BASAVESHWARANAGAR",
    "BOMMANHALLI",
    "CHAMRAJPET",
    "CHANDRA LAY OUT",
    "CHOLURPALYA",
    "CITY",
    "GANGAMMA CIRCLE",
    "GANGANAGAR",
    "GOKULA",
    "GORAGUNTEPALYA",
    "HANUMANTHNAGAR",
    "HEBBAL",
    "HEGGANAHALLI",
    "JALAHALLI",
    "JAYANAGAR",
    "K.G.HALLI",
    "KAMAKSHIPALYA",
    "KATRIGUPPE",
    "KENGERI",
    "KURABARHALLI",
    "LAGGERE",
    "MAGADI ROAD",
    "MAHALAXMI LAY OUT",
    "MALLESHWARAM",
    "MANJUNATH NAGAR",
    "MARIYAPPANPALYA",
    "MATHIKERE",
    "PEENYA 2ND STAGE",
    "R R NAGAR",
    "RAJAJINAGAR",
    "RAMCHANDRAPURAM",
    "RPC LAYOUT",
    "SANJAYNAGAR",
    "SARAKKI",
    "SHIVAJI NAGAR",
    "SRINAGAR",
    "SRIRAMPURAM",
    "T.DASARAHALLI",
    "THYAGRAJNAGAR",
    "ULLAL",
    "VIDHYARANYAPURA",
    "VIJAYANAGAR",
    "VIJAYANAGAR-2",
    "WEST OF CHORD ROAD",
    "WILSON GARDEN",
    "YELAHANKA",
    "YESHWANTHPUR",
    "OTHERS",
  ],

  BHILWARA: [
    "AMDALA",
    "AMLI",
    "ASIND",
    "BAGOLIYA",
    "BEMALI",
    "BHAGAWANPURA",
    "BHILWARA",
    "BHITANAGAR",
    "BORANA",
    "CHANKHED",
    "CHATAWATI",
    "CHAWANDIYA",
    "CHILESHWAR",
    "CHITAMBA",
    "DEVRIYA",
    "GAJUNA",
    "GANGAPUR",
    "GILDIYA",
    "GIRDIYA",
    "GODAS",
    "GORKHIYA",
    "GYANGARH",
    "JADAN",
    "JEERAN",
    "JHALARA",
    "KALAS",
    "KALIYAS",
    "KAMERI",
    "KAMERI CHOUHANO KI",
    "KAREDA",
    "KATAR",
    "KHAJURIYA",
    "KHEMANA",
    "KIDIMAL",
    "KOSHITHAL",
    "KUNDWA",
    "LADUVAS",
    "MANDAL",
    "MOKHUNDA",
    "NATHDIYAS",
    "NEGDIYA KA KHEDA",
    "PITHAS",
    "POTLA",
    "PUR",
    "RAIPUR",
    "RANAS",
    "SALERA",
    "SAMELIYA",
    "SHIVPUR",
    "SODAS",
    "THANA",
    "ULLAI",
    "UMRI",
  ],

  CHITTORGARH: ["MUROLI", "RASHMI", "REWADA", "CHITTORGARH"],

  GOA: ["MADGAON"],

  "GUJRAT NORTH ZONE": [
    "AAKEDI",
    "AMBAJI",
    "BADOLI",
    "BAYAD",
    "BHILODA",
    "CHOILA",
    "DANTA",
    "DELVAD",
    "DEMAI",
    "DHANSURA",
    "GABAT",
    "GOJARIYA",
    "GRAMBHARATI",
    "HINMMATNAGAR",
    "IDER",
    "KADPAVANJ",
    "KHERANCHA",
    "KHEDBRHMA",
    "KUKERWADA",
    "LIMBDIYA",
    "LUNAVADA",
    "MEHSANA",
    "MALEKPUR",
    "MALPUR",
    "MANSA",
    "MEGHRAJ",
    "MEMADPUR",
    "MODASA",
    "PALANPUR",
    "PANDARWADA",
    "PARBATPURA",
    "PATAN",
    "POSHINA",
    "PRANTIJ",
    "RANASHAN",
    "SATHAMBA",
    "SIDHPUR",
    "SHIMALVADA",
    "UBHRAN",
    "UNAVA",
    "VADALI",
    "VADGAUM",
    "VIJAPUR",
    "VIRPUR",
    "OTHER THEN LIST",
  ],

  "GUJRAT SOUTH ZONE": [
    "VADODARA",
    "AARAK SISODRA",
    "ABRAMA",
    "AMADPORE",
    "AMARI",
    "AMBADA",
    "ATGAM",
    // "BAYAD",
    "BILIMORA",
    "BODLAI",
    "CHIKHALI",
    "DHAMAN",
    "DHANORI",
    "DUNGARI",
    "GABAT",
    "GORGAM",
    "GREED",
    "GUNDLAV",
    "HARI",
    "IDAR",
    "KABILPORE",
    "KALIYAWADI",
    "KALVADA",
    "KANJANHARI",
    "KILLA PARADI",
    "VAPI",
    "KHEDA",
    "KHOKHARA",
    "MANSA",
    "MAROLI",
    "MEGHRAJ",
    "MODASA",
    "NADIYAD",
    "NAVSARI",
    "PALANPUR",
    "PARTHAN",
    "PEETHA",
    "PETHAPUR",
    "RONVEL",
    "SANDALPORE",
    "SATHANMBA",
    "SELVASVA",
    "SISODRA",
    "TEEGARA",
    "UGAT",
    "VADALI",
    "VAGALDHRA",
    "VALSAD",
    "VESMA",
    "VIJALPORE",
    "OTHER THEN LIST",
  ],

  "HUBLI ZONE": [
    "BELGAUM",
    "BELLARY",
    "DAVANGERE",
    "GADAG",
    "HUBLI",
    "SIMOGA",
    "OTHER THAN LIST",
  ],

  KERALA: ["KOZHIKODE", "ERNAKULAM", "OTHER THAN LIST"],

  "KOKAN ZONE": [
    "BIREWADI",
    "CHIPLUN",
    "CHOK",
    "DABHOL",
    "DAPOLI",
    "DASGAON",
    "GOREGAON",
    "KHED",
    "MAHAD",
    "MANDANGAON",
    "MANGAON",
    "MHALSA",
    "MHAPRAL",
    "RATNAGIRI",
    "REVDANDA",
    "VAKVALI",
    "OTHER THAN LIST",
  ],

  MUMBAI: ["MUMBAI"],

  "MYSORE ZONE": [
    "BANNUR",
    "CHAMRAJNAGAR",
    "DAVANGERE",
    "GADAG",
    "CHANPATANA",
    "CHANRAYAPATANA",
    "GUNDALPET",
    "H.D.KOTE",
    "HAND POST",
    "HUNSUR",
    "HASSAN",
    "HOLENARSIPUR",
    "MANDYA",
    "NANJANGUD",
    "PANDAVPURA",
    "PIRIYAPATANA",
    "SAKALESHPUR",
    "SARGUR",
    "SHRAVANBELGOLA",
    "SRIRANGPATANA",
    "T.NARSIPUR",
    "OTHER THEN LIST",
  ],

  MYSURU: ["MYSURU"],

  RAJSAMAND: [
    "AMET",
    "ANJANA",
    "ASAN",
    "BAGANA",
    "BAGGAD",
    "BARAR",
    "BARJAL",
    "BHIM",
    "CHAPALI",
    "CHARBHUJA ROAD",
    "DEOGARH",
    "DOLAJI KA KHEDA",
    "DOLPURA",
    "GHATAWAR",
    "GOGATHALA",
    "ISARMAND",
    "JUNADA",
    "KALADEH",
    "KALESARIYA",
    "KAMLIGHAT",
    "KANKROLI",
    "KELWA",
    "KHAKHARMALA",
    "KHARNOTA",
    "KUKARKHEDA",
    "KUNWARIYA",
    "KUNWATHAL",
    "LAKHAGUDA",
    "LASANI",
    "MADARIYA",
    "MANDAWAR",
    "NANANA",
    "NARANA",
    "NARDASJI KA GUDA",
    "NATHDWARA",
    "NEEMJAR",
    "PANOTIYA",
    "PEETHA KA KHEDA",
    "PIPALINAGAR",
    "RAJNAGAR",
    "RAJSAMAND",
    "SAKARAWAS",
    "SANGAWAS",
    "SARDARGARH",
    "SAREVADI",
    "SEVANTRI",
    "SOHANGARH",
    "TAL",
    "THIKARWAS",
    "OTHER THAN LIST",
  ],

  SURAT: [
    "ADAJAN-PAL",
    "ALTHAN-BHATAR ROAD",
    "AMROLI",
    "ANKLESHWAR-ILAV",
    "ASHVINIKUMAR ROAD",
    "BARDOLI",
    "BRODA PRISTAG-TAPI BAGH",
    "BHESTAN",
    "CITY LIGHT-BHATAR ROAD",
    "AMBAJI-KAILSH NGR-SANGRAMPURA-MAJURA GATE",
    "HIRABAGH-KAPODRA",
    "KADODARA-CHANTHAN-PALSANA",
    "KAMREJ",
    "KATARGAM",
    "KAVAS-HAJIRA-ICHAPUR-MORA",
    "KIM",
    "MAROLI",
    "NANA VARACHA",
    "NAVAGAM-DINDOLI",
    "OLPAD",
    "PANDESARA",
    "PARVAT PATIYA",
    "PUNNAGAM",
    "RANDER",
    "SACJIN-TALANGPUR",
    "SAYAN",
    "SUMUL DAIRY ROAD",
    "TRIKAM NAGAR-MATAWADI",
    "UDHNA",
    "UDHANAYARD (LIMBAYAT)-GODADARA",
    "VEDROAD",
    "VIP ROAD (BHATAR ROAD)",
    "VYARA-BAJIPUR-MANDVI",
    "OTHERS",
  ],

  "ANDRA PRADESH/TELENGANA": ["HYDRABAD/SECUNDRABAD", "OTHER THAN LIST"],

  UDAIPUR: ["UDAIPUR", "OTHER THAN LIST"],

  "OTHER FROM LIST ZONE": ["OTHER"],

  VIDESH: [
    "CHINA",
    "UNITED STATE",
    "RUSSIA",
    "JAPAN",
    "UNITED KINGDOM",
    "FRANCE",
    "ITALY",
    "SOUTH AFRICA",
    "AUSTRALIA",
    "OTHER",
  ],
};

// zone(city), area

export const ZonesData = {
  AHMEDABAD: [
    "SHAHIBAG",
    "MANINAGAR",
    "SHANTINAGAR-USMANPURA-VAIS",
    "GHATLODIYA-CHANDLODIYA-NAVRANGPURA",
    "BAPUNAGAR-ODHAV-VASTRAPUR",
    "MEMNAGAR-SETELITE-BODAKDEV",
    "SUBHASBRIJ-SABARMATI-RANIP",
    "KHEDA-ASHLALI-JETALPUR-DASHKOY",
    "OTHER AREA IN AHMEDABAD",
    "GANDHINAGAR",
  ],

  // "GANDHINAGAR": [
  //   "SHAHIBAG",
  //   "MANINAGAR",
  //   "SHANTINAGAR-USMANPURA-VAIS",
  //   "GHATLODIYA-CHANDLODIYA-NAVRANGPURA",
  //   "BAPUNAGAR-ODHAV-VASTRAPUR",
  //   "MEMNAGAR-SETELITE-BODAKDEV",
  //   "SUBHASBRIJ-SABARMATI-RANIP",
  //   "KHEDA-ASHLALI-JETALPUR-DASHKOY",
  //   "OTHER AREA IN AHMEDABAD",
  //   "GANDHINAGAR",
  // ],

  "AJMER/JAIPUR": ["AJMER", "JAIPUR", "TODGARH", "OTHER"],

  BANGALORE: [
    "A. DASARHALLI",
    "ATTIGUPPE",
    "BAPUJINAGAR",
    "BASAVESHWARANAGAR",
    "BOMMANHALLI",
    "CHAMRAJPET",
    "CHANDRA LAY OUT",
    "CHOLURPALYA",
    "CITY",
    "GANGAMMA CIRCLE",
    "GANGANAGAR",
    "GOKULA",
    "GORAGUNTEPALYA",
    "HANUMANTHNAGAR",
    "HEBBAL",
    "HEGGANAHALLI",
    "JALAHALLI",
    "JAYANAGAR",
    "K.G.HALLI",
    "KAMAKSHIPALYA",
    "KATRIGUPPE",
    "KENGERI",
    "KURABARHALLI",
    "LAGGERE",
    "MAGADI ROAD",
    "MAHALAXMI LAY OUT",
    "MALLESHWARAM",
    "MANJUNATH NAGAR",
    "MARIYAPPANPALYA",
    "MATHIKERE",
    "PEENYA 2ND STAGE",
    "R R NAGAR",
    "RAJAJINAGAR",
    "RAMCHANDRAPURAM",
    "RPC LAYOUT",
    "SANJAYNAGAR",
    "SARAKKI",
    "SHIVAJI NAGAR",
    "SRINAGAR",
    "SRIRAMPURAM",
    "T.DASARAHALLI",
    "THYAGRAJNAGAR",
    "ULLAL",
    "VIDHYARANYAPURA",
    "VIJAYANAGAR",
    "VIJAYANAGAR-2",
    "WEST OF CHORD ROAD",
    "WILSON GARDEN",
    "YELAHANKA",
    "YESHWANTHPUR",
    "OTHERS",
  ],

  BHILWARA: [
    "AMDALA",
    "AMLI",
    "ASIND",
    "BAGOLIYA",
    "BEMALI",
    "BHAGAWANPURA",
    "BHILWARA",
    "BHITANAGAR",
    "BORANA",
    "CHANKHED",
    "CHATAWATI",
    "CHAWANDIYA",
    "CHILESHWAR",
    "CHITAMBA",
    "DEVRIYA",
    "GAJUNA",
    "GANGAPUR",
    "GILDIYA",
    "GIRDIYA",
    "GODAS",
    "GORKHIYA",
    "GYANGARH",
    "JADAN",
    "JEERAN",
    "JHALARA",
    "KALAS",
    "KALIYAS",
    "KAMERI",
    "KAMERI CHOUHANO KI",
    "KAREDA",
    "KATAR",
    "KHAJURIYA",
    "KHEMANA",
    "KIDIMAL",
    "KOSHITHAL",
    "KUNDWA",
    "LADUVAS",
    "MANDAL",
    "MOKHUNDA",
    "NATHDIYAS",
    "NEGDIYA KA KHEDA",
    "PITHAS",
    "POTLA",
    "PUR",
    "RAIPUR",
    "RANAS",
    "SALERA",
    "SAMELIYA",
    "SHIVPUR",
    "SODAS",
    "THANA",
    "ULLAI",
    "UMRI",
  ],

  CHITTORGARH: ["MUROLI", "RASHMI", "REWADA", "CHITTORGARH"],

  GOA: ["MADGAON"],

  "GUJRAT NORTH ZONE": [
    "AAKEDI",
    "AMBAJI",
    "BADOLI",
    "BAYAD",
    "BHILODA",
    "CHOILA",
    "DANTA",
    "DELVAD",
    "DEMAI",
    "DHANSURA",
    "GABAT",
    "GOJARIYA",
    "GRAMBHARATI",
    "HINMMATNAGAR",
    "IDER",
    "KADPAVANJ",
    "KHERANCHA",
    "KHEDBRHMA",
    "KUKERWADA",
    "LIMBDIYA",
    "LUNAVADA",
    "MEHSANA",
    "MALEKPUR",
    "MALPUR",
    "MANSA",
    "MEGHRAJ",
    "MEMADPUR",
    "MODASA",
    "PALANPUR",
    "PANDARWADA",
    "PARBATPURA",
    "PATAN",
    "POSHINA",
    "PRANTIJ",
    "RANASHAN",
    "SATHAMBA",
    "SIDHPUR",
    "SHIMALVADA",
    "UBHRAN",
    "UNAVA",
    "VADALI",
    "VADGAUM",
    "VIJAPUR",
    "VIRPUR",
    "OTHER THEN LIST",
  ],

  "GUJRAT SOUTH ZONE": [
    // "VADODARA",
    "AARAK SISODRA",
    "ANDHAL",
    "ATUL",
    "ABRAMA",
    "AMADPORE",
    "AMARI",
    "AMBADA",
    "ATGAM",
    // "BAYAD",
    "ATUL",
    "VASANDA",
    "ANDHAL",
    "GANDEVA",
    "KHAREL",
    "DEGAM",
    "VAPI",
    "BILIMORA",
    "BODLAI",
    "CHIKHALI",
    "DHAMAN",
    "DHANORI",
    "DUNGARI",
    "GABAT",
    "GANDEVA",
    "GORGAM",
    "GREED",
    "GUNDLAV",
    "HARI",
    "IDAR",
    "KABILPORE",
    "KALIYAWADI",
    "KALVADA",
    "KANJANHARI",
    "KHAREL",
    "KILLA PARADI",
    "VAPI",
    "KHEDA",
    "KHOKHARA",
    // "MANSA",
    "MAROLI",
    // "MEGHRAJ",
    // "MODASA",
    // "NADIYAD",
    "NAVSARI",
    // "PALANPUR",
    "PARTHAN",
    "PEETHA",
    "PETHAPUR",
    "RONVEL",
    "SANDALPORE",
    // "SATHANMBA",
    "SELVASA",
    "SISODRA",
    "TEEGARA",
    "UGAT",
    // "VADALI",
    "VAGALDHRA",
    "VALSAD",
    "VASANDA",
    "VESMA",
    "VIJALPORE",
    "OTHER THEN LIST",
  ],

  "HUBLI ZONE": [
    "BELGAUM",
    "BELLARY",
    "DAVANGERE",
    "GADAG",
    "HUBLI",
    "SIMOGA",
    "OTHER THAN LIST",
  ],

  KERALA: ["KOZHIKODE", "ERNAKULAM", "OTHER THAN LIST"],

  "KOKAN ZONE": [
    "BIREWADI",
    "CHIPLUN",
    "CHOK",
    "DABHOL",
    "DAPOLI",
    "DASGAON",
    "GOREGAON",
    "KHED",
    "MAHAD",
    "MANDANGAON",
    "MANGAON",
    "MHALSA",
    "MHAPRAL",
    "RATNAGIRI",
    "REVDANDA",
    "VAKVALI",
    "OTHER THAN LIST",
  ],

  MUMBAI: ["MUMBAI"],

  "MYSORE ZONE": [
    "BANNUR",
    "CHAMRAJNAGAR",
    "DAVANGERE",
    "GADAG",
    "CHANPATANA",
    "CHANRAYAPATANA",
    "GUNDALPET",
    "H.D.KOTE",
    "HAND POST",
    "HUNSUR",
    "HASSAN",
    "HOLENARSIPUR",
    "MANDYA",
    "K.R.NAGAR",
    "K.R.PET",
    "KUSHALNAGAR",
    "NAGAMANGALA",
    "SAKLESHPUR",
    "NANJANGUD",
    "PANDAVPURA",
    "PIRIYAPATANA",
    "SAKALESHPUR",
    "SARGUR",
    "SHRAVANBELGOLA",
    "SRIRANGPATANA",
    "T.NARASIPUR",
    "OTHER THEN LIST",
  ],

  MYSURU: ["MYSURU"],

  RAJSAMAND: [
    "AMET",
    "ANJANA",
    "ASAN",
    "BAGANA",
    "BAGGAD",
    "BARAR",
    "BARJAL",
    "BHIM",
    "CHAPALI",
    "CHARBHUJA ROAD",
    "DEOGARH",
    "DOLAJI KA KHEDA",
    "DOLPURA",
    "GHATAWAR",
    "GOGATHALA",
    "ISARMAND",
    "JUNADA",
    "KALADEH",
    "KALESARIYA",
    "KAMLIGHAT",
    "KANKROLI",
    "KELWA",
    "KHAKHARMALA",
    "KHARNOTA",
    "KUKARKHEDA",
    "KUNWARIYA",
    "KUNWATHAL",
    "LAKHAGUDA",
    "LASANI",
    "MADARIYA",
    "MANDAWAR",
    "NANANA",
    "NARANA",
    "NARDASJI KA GUDA",
    "NATHDWARA",
    "NEEMJAR",
    "PANOTIYA",
    "PEETHA KA KHEDA",
    "PIPALINAGAR",
    "RAJNAGAR",
    "RAJSAMAND",
    "SAKARAWAS",
    "SANGAWAS",
    "SARDARGARH",
    "SAREVADI",
    "SEVANTRI",
    "SOHANGARH",
    "TAL",
    "THIKARWAS",
    "OTHER THAN LIST",
  ],

  SURAT: [
    "ADAJAN-PAL",
    "ALTHAN-BHATAR ROAD",
    "AMROLI",
    "ANKLESHWAR-ILAV",
    "ASHVINIKUMAR ROAD",
    "BARDOLI",
    "BRODA PRISTAG-TAPI BAGH",
    "BHESTAN",
    "CITY LIGHT-BHATAR ROAD",
    "AMBAJI-KAILSH NGR-SANGRAMPURA-MAJURA GATE",
    "HIRABAGH-KAPODRA",
    "KADODARA-CHANTHAN-PALSANA",
    "KAMREJ",
    "KATARGAM",
    "KAVAS-HAJIRA-ICHAPUR-MORA",
    "KIM",
    "MAROLI",
    "NANA VARACHA",
    "NAVAGAM-DINDOLI",
    "NADIYAD",
    "OLPAD",
    "PANDESARA",
    "PARVAT PATIYA",
    "PUNNAGAM",
    "RANDER",
    "SACJIN-TALANGPUR",
    "SAYAN",
    "SUMUL DAIRY ROAD",
    "TRIKAM NAGAR-MATAWADI",
    "UDHNA",
    "UDHANAYARD (LIMBAYAT)-GODADARA",
    "VADODARA",
    "VEDROAD",
    "VIP ROAD (BHATAR ROAD)",
    "VYARA-BAJIPUR-MANDVI",
    "OTHERS",
  ],

  "ANDRA PRADESH": [],

  CHENNAI: ["CHENNAI"],

  TELENGANA: ["HYDRABAD/SECUNDRABAD", "OTHER THAN LIST"],

  UDAIPUR: ["UDAIPUR", "OTHER THAN LIST"],

  "MADHYA PRADESH": ["INDORE"],

  "OTHER FROM LIST ZONE": ["OTHER"],
};
