import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";

const Selector = ({ name, data, setFilters, filters, url }: any) => {
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);

  const returnName = () => {
    if (url && url.get("zone")) {
      setSelected(url.get("zone"));
    } else if (url && url.get("area")) {
      setSelected(url.get("area"));
    }
  };

  useEffect(() => {
    returnName();
  }, []);

  return (
    <div className={`w-72 font-medium h-10 m-2`}>
      <div
        onClick={() => setOpen(!open)}
        className={`bg-white w-full p-2 flex items-center justify-between rounded cursor-pointer ${
          !selected && "text-gray-700"
        }`}
      >
        {selected
          ? selected?.length > 25
            ? selected?.substring(0, 25) + "..."
            : selected
          : `Select ${name}`}
        <BiChevronDown size={20} className={`${open && "rotate-180"}`} />
      </div>
      <ul
        className={`bg-gray-300 mt-1 overflow-y-auto absolute z-10 ${
          open ? "max-h-60" : "max-h-0"
        } `}
      >
        <div className="flex items-center px-2 sticky top-0 bg-white">
          <div>
            <AiOutlineSearch size={18} className="text-gray-700" />
          </div>
          <input
            style={{
              width: "230px",
            }}
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value.toLowerCase())}
            placeholder={`Enter ${name} name`}
            className="placeholder:text-lightgray-700 p-2 my-2 border-0 ml-2"
          />
        </div>
        {data.map((data: any, key: any) => (
          <li
            key={key}
            className={`p-2 text-sm cursor-pointer hover:bg-sky-600 hover:text-indigo-500
            ${
              data?.toLowerCase() === selected?.toLowerCase() &&
              "bg-sky-600 text-indigo-500"
            }
            ${data?.toLowerCase().startsWith(inputValue) ? "block" : "hidden"}`}
            onClick={async () => {
              setSelected(data);
              setOpen(false);
              setInputValue("");

              if (name === "Zone") {
                setFilters({
                  city: data,
                  area: filters.city,
                });
              }
              if (name === "Area") {
                setFilters({
                  city: filters.city,
                  area: data,
                });
              }
            }}
          >
            {data}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Selector;
